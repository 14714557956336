import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import { Box, Grid, Link, Stack, Tab, TextField, Typography } from "@mui/material";
import { Login } from "Modules/Core/AuthV2/Login";
import { SocialLogin } from "Modules/Core/AuthV2/SocialLogin";
import { StrongFitIcon } from 'assets/Icons';
import { IsSmScreen, useAppDispatch, useAppSelector } from 'helpers/hooks';
import { ChangeEvent, useEffect, useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { TabPanelProps } from "shared/SharedModels";
import { ShAlert } from 'shared/SharedStyles/ShFeedback';
import { ShGreenBtn } from 'shared/SharedStyles/ShInputs';
import { ShMuiLink, ShTabs } from 'shared/SharedStyles/ShNavigation';
import { ShPaper } from 'shared/SharedStyles/ShSurfaces';
import { ShGreen, WhiteColor } from 'shared/SharedStyles/styleConstants';
import { EmailRegEx } from 'shared/constants';
import { checkIfAccountExists, resetCheckIfAccountExists, setAppContext } from 'store/slices/auth-v2/auth-v2-slice';
import { StyledCarousel } from './AppLogin.styled';

const TabPanel = (props: TabPanelProps) => {
    const { children, value, index } = props;
    return (
        <Box role='tabpanel' hidden={value !== index}>{value === index && (<>{children}</>)}</Box>
    );
}

export const AppLogin = () => {

    const navigate = useNavigate();
    const isSmScreen = IsSmScreen();
    const dispatch = useAppDispatch();
    const { checkIfAccountExistsStatus, checkIfAccountExistsResponseMsg,
        checkIfAccountExistsResponse } = useAppSelector((state) => state.auth.auth);
    const [tabValue, setTabValue] = useState<number>(0);
    const [signUpEmail, setSignUpEmail] = useState<string>('');
    const [isSignUpEmailInvalid, setIsSignUpEmailInvalid] = useState(false);

    const validateEmail = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined) => {
        const reg = new RegExp(EmailRegEx);
        setIsSignUpEmailInvalid(!reg.test(e?.target.value ?? ''));
        setSignUpEmail(e?.target.value ?? '');
    };

    const onAppLoginTabsChange = (tabValue: number) => {
        setTabValue(tabValue);
        dispatch(resetCheckIfAccountExists());
    }

    // set app context here.
    useEffect(() => {
        dispatch(setAppContext({ currentAppContext: 'employer' }));
    }, [dispatch]);

    useEffect(() => {
        if (checkIfAccountExistsStatus === 'success') {
            if (!checkIfAccountExistsResponse?.exists) {
                navigate('/accounts/sign-up', { state: { email: signUpEmail } });
                dispatch(resetCheckIfAccountExists());
            }
        }
    }, [checkIfAccountExistsResponse?.exists, checkIfAccountExistsStatus, dispatch, navigate, signUpEmail]);

    return (<>
        <Grid container height='100%' alignItems='center' spacing={{ md: 3, lg: 6, xl: 7 }}
            rowGap={2} padding={{ xs: 1, sm: 2, md: 2, lg: 3 }}>
            <Grid item xs={12} md={5.5} lg={4} xl={3}>
                <ShPaper minHeight={500}>
                    <Box paddingX={2}>
                        <ShTabs aria-label="Security tabs" variant="scrollable" scrollButtons='auto' highlightVariant='underline'
                            fontSize='medium' fontWeight={600} highlightColor={ShGreen}
                            allowScrollButtonsMobile value={tabValue} onChange={(event, newValue) => onAppLoginTabsChange(newValue)}>
                            <Tab label='Login' />
                            <Tab label='Sign up' />
                        </ShTabs >
                        <TabPanel value={tabValue} index={0}>
                            <Box padding={{ xs: 1, sm: 2, md: 2, lg: 3 }} paddingTop={{ xs: 3, sm: 3, md: 3 }}
                                paddingX={{ xs: 0, sm: 0, md: 0, lg: 0 }}>
                                <Login isShowSocialLogin usedIn='employer' />
                            </Box>
                        </TabPanel>
                        <TabPanel value={tabValue} index={1}>
                            <Stack rowGap={3} padding={{ xs: 2, sm: 2, md: 2, lg: 2 }} paddingX={{ xs: 0, sm: 0, md: 0, lg: 0 }}>
                                {checkIfAccountExistsResponse?.exists &&
                                    <ShAlert severity='info' onClose={() => dispatch(resetCheckIfAccountExists())}>
                                        {checkIfAccountExistsResponseMsg}</ShAlert>
                                }
                                <Stack flexDirection='row' alignItems='center' mb={3} columnGap={1}>
                                    <StrongFitIcon />
                                    <Typography variant='body2' fontSize='inherit' lineHeight={{ xs: 2, sm: 'normal', md: 'normal' }}>
                                        Looking for acclaim badges?&nbsp;
                                        {isSmScreen && <><br></br></>}
                                        <Link component={RouterLink} to='/candidate/new' fontWeight='bold'
                                            underline='hover'>Sign Up</Link>
                                        &nbsp;here
                                    </Typography>
                                </Stack>
                                <TextField size='small' id='mail' label='Email' variant='outlined'
                                    value={signUpEmail} onChange={validateEmail} margin='dense' />
                                <ShGreenBtn className='self-center' variant='contained' disableElevation
                                    disabled={signUpEmail?.trim()?.length === 0 || isSignUpEmailInvalid
                                        || checkIfAccountExistsStatus === 'pending'}
                                    onClick={() => dispatch(checkIfAccountExists({ email: signUpEmail }))}>
                                    {checkIfAccountExistsStatus === 'pending' ? 'Validating Email...' : 'Next'}
                                </ShGreenBtn>
                                <Typography variant='body2' alignSelf='center' marginY={2}>Or Sign up with</Typography>
                                {/* Social Login component handles all the OAuth provider sign in. */}
                                {tabValue === 1 && <Box alignSelf='center'>
                                    <SocialLogin isSignUp={true} buttonType="icon" usedIn='employer' />
                                </Box>}
                                <Link variant='body2' alignSelf='center' target='_blank'
                                    href='https://smoothhiring.com/privacy-policy/'>Privacy Policy</Link>
                            </Stack>
                        </TabPanel>
                    </Box>
                </ShPaper>
            </Grid>
            <Grid item xs={12} md={6.5} lg={8} xl={9} justifyContent='flex-end !important'>
                {/* 'Carousel' from 'react-material-ui-carousel' to show banners and other information about the product.
                    Customizable with various input props like custom navigation and indicator buttons etc. 
                */}
                <StyledCarousel
                    NextIcon={<ArrowCircleRightOutlinedIcon />}
                    PrevIcon={<ArrowCircleLeftOutlinedIcon />}
                    autoPlay={false} navButtonsAlwaysVisible={true}
                    navButtonsWrapperProps={{ className: 'carousel-btns' }}
                    indicatorContainerProps={{ className: 'indicator-btns-cntnr' }}
                    indicatorIconButtonProps={{ className: 'indicator-btns' }}
                    IndicatorIcon={<CircleOutlinedIcon fontSize='small' />}
                    activeIndicatorIconButtonProps={{ className: 'active-item-indicator' }}>
                    <Stack className='carousel-items item1'>
                        <Typography variant='h5' gutterBottom className='item-header'>
                            Post your job to multiple job boards
                        </Typography>
                        <Stack className='banner'>
                            <ShMuiLink href='https://smoothhiring.com/' color={WhiteColor} outlined
                                underLineOnHover noUnderline display='flex' alignItems='center' columnGap={1} target='_blank'>
                                See Details
                                <ArrowCircleRightIcon />
                            </ShMuiLink>
                            {/* Container to hold the image of available Job Boards */}
                            <Box className='job-boards'></Box>
                        </Stack>
                    </Stack>
                    <Stack className='carousel-items item2'>
                        <Typography variant='h5' gutterBottom className='item-header'>
                            Instantly Identify Winning Attributes Among Applicants
                        </Typography>
                        <Stack className='banner'>
                            <ShMuiLink href='https://smoothhiring.com/' color={WhiteColor} outlined
                                underLineOnHover noUnderline display='flex' alignItems='center' columnGap={1} target='_blank'>
                                See Details
                                <ArrowCircleRightIcon />
                            </ShMuiLink>
                        </Stack>
                    </Stack>
                    <Stack className='carousel-items item3'>
                        <Typography variant='h5' gutterBottom className='item-header'>
                            Reach More And Hire The Best Employees
                        </Typography>
                        <Stack className='banner'>
                            <ShMuiLink href='https://smoothhiring.com/' color={WhiteColor} outlined
                                underLineOnHover noUnderline display='flex' alignItems='center' columnGap={1} target='_blank'>
                                See Details
                                <ArrowCircleRightIcon />
                            </ShMuiLink>
                        </Stack>
                    </Stack>
                </StyledCarousel>
            </Grid>
        </Grid>
    </>);
};