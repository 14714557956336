import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Container, Grid, IconButton, InputAdornment, Paper, Stack, TextField, Typography } from "@mui/material";
import { ICandidateSignUpPayload } from "Modules/Core/Candidate/CandidateModel";
import { useAppDispatch, useAppSelector } from "helpers/hooks";
import { ChangeEvent, useEffect, useState } from "react";
import { PasswordValidator } from "shared/SharedComponents/PasswordValidator";
import { ShButton, ShGreenBtn } from "shared/SharedStyles/ShInputs";
import { EmailRegEx } from "shared/constants";
import { logoutAccount } from 'store/slices/auth-v2/auth-v2-slice';
import { applyForJob, resetApplyForJob } from "store/slices/candidate/apply-job/apply-job-home-slice";
import { resetGetCandidateDetails, resetSignUpCandidate, signUpCandidate } from "store/slices/candidate/candidate-profile-slice";
import { CandidatePrivacyPolicyStatement } from './ApplyJobConstants';
import { IApplyJobSignUpComponent } from "./ApplyJobModel";

export const ApplyJobSignUp = ({ setCurrentJobApplyStep, prefillEmail,
    selectedResume, navigatedFrom, uploadResume }: IApplyJobSignUpComponent) => {

    const dispatch = useAppDispatch();
    const { candidateInfoInResume, uploadResumeStatus } = useAppSelector((state) => state.employer.resume);
    const { candidateSignUpStatus, candidateDetails } = useAppSelector((state) => state.candidate.candidateProfile);
    const { jobDetailsByCode } = useAppSelector(state => state.employer.employerJobs.jobInfo);
    const {
        jobApplicationStatus, jobApplicationId, postingSource } = useAppSelector((state) => state.candidate.applyJobHome);

    const [candidateSignUpPayload, setCandidateSignUpPayload] = useState<ICandidateSignUpPayload>(() => {
        return {
            email: candidateInfoInResume?.email ?? prefillEmail ?? '',
            password: '',
            first_name: candidateInfoInResume?.firstName ?? '',
            last_name: candidateInfoInResume?.lastName ?? '',
            job_id: jobDetailsByCode?.id,
            ps: postingSource
        }
    });
    const [isEmailInvalid, setIsEmailInvalid] = useState<boolean>(false);
    const [isPasswordValid, setIsPasswordValid] = useState<boolean>(false);
    const [isShowPassword, setIsShowPassword] = useState<boolean>(false);
    const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(false);

    const validateEmail = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const reg = new RegExp(EmailRegEx);
        setIsEmailInvalid(!reg.test(e.target.value));
    };

    useEffect(() => {
        if (candidateSignUpStatus === 'success') {
            if (jobDetailsByCode?.id) {
                dispatch(applyForJob({ jobId: jobDetailsByCode?.id.toString(), postingSource: postingSource }));
            }
            dispatch(resetSignUpCandidate());
        }
    }, [candidateSignUpStatus, dispatch, jobDetailsByCode?.id, postingSource]);

    useEffect(() => {
        if (jobApplicationStatus === 'success' && jobApplicationId && uploadResume) {
            uploadResume();
            dispatch(resetApplyForJob());
        }
    }, [dispatch, jobApplicationId, jobApplicationStatus, selectedResume, uploadResume])

    useEffect(() => {
        const { email, first_name, last_name, password } = candidateSignUpPayload;
        setIsSubmitDisabled(!email || !first_name || !last_name || !password || isEmailInvalid || !isPasswordValid);
    }, [candidateSignUpPayload, isEmailInvalid, isPasswordValid]);

    return (<>
        <Container maxWidth='sm'>
            {
                navigatedFrom === 'resume_upload' &&
                <Typography variant='body2' textAlign='center' gutterBottom mb={2}>
                    {`Hi ${candidateSignUpPayload?.first_name ?? ''}, please enter the below details.`}
                </Typography>
            }
            <Paper variant='outlined'>
                <Stack rowGap={3} alignSelf='center' alignItems='center'
                    padding={{ xs: 1, sm: 2, md: 2, lg: 3 }}>
                    {!candidateDetails?.jobApplicationId &&
                        <Grid container spacing={{ xs: 2, sm: 2, md: 3, lg: 3 }}>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <TextField label="First name" variant="outlined" fullWidth size='small' required
                                    value={candidateSignUpPayload.first_name}
                                    onChange={(e) => {
                                        setCandidateSignUpPayload({ ...candidateSignUpPayload, first_name: e.target.value })
                                    }}></TextField>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <TextField label="Last name" variant="outlined" fullWidth size='small' required
                                    value={candidateSignUpPayload.last_name}
                                    onChange={(e) => {
                                        setCandidateSignUpPayload({ ...candidateSignUpPayload, last_name: e.target.value })
                                    }}></TextField>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <TextField label="Email Address" variant="outlined" fullWidth size='small' required
                                    value={candidateSignUpPayload.email}
                                    onChange={(e) => {
                                        setCandidateSignUpPayload({ ...candidateSignUpPayload, email: e.target.value });
                                        validateEmail(e)
                                    }}
                                    error={isEmailInvalid}></TextField>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <TextField label="Password" variant="outlined" fullWidth size='small' required
                                    type={isShowPassword ? 'text' : 'password'} InputProps={{
                                        endAdornment: <InputAdornment position='end'>
                                            <IconButton
                                                edge='end' onClick={() => setIsShowPassword(!isShowPassword)}>
                                                {isShowPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                            </IconButton>
                                        </InputAdornment>
                                    }} value={candidateSignUpPayload.password}
                                    onChange={(e) => { setCandidateSignUpPayload({ ...candidateSignUpPayload, password: e.target.value }) }}>
                                </TextField>
                                {candidateSignUpPayload.password?.length > 0 &&
                                    <PasswordValidator password={candidateSignUpPayload.password}
                                        confirmPassword={candidateSignUpPayload.password}
                                        onValidityChange={e => setIsPasswordValid(e)}
                                    />}
                            </Grid>
                        </Grid>}
                    <Stack direction='row' justifyContent='space-between' width='100%' >
                        <ShButton variant="outlined" onClick={() => {
                            setCurrentJobApplyStep(navigatedFrom);
                            navigatedFrom === 'login' && dispatch(logoutAccount({ logoutType: 'user' }));
                            dispatch(resetGetCandidateDetails());
                        }}>Back</ShButton>
                        {candidateDetails?.jobApplicationId && !candidateDetails?.resumeComplete ? <>
                            <ShGreenBtn variant="outlined" size="small"
                                disabled={uploadResumeStatus === 'pending' || !selectedResume}
                                onClick={() => uploadResume && uploadResume()}>
                                {uploadResumeStatus === 'pending' ? 'Uploading Resume...' : 'Upload Resume'}
                            </ShGreenBtn>
                        </> :
                            <>
                                <ShGreenBtn variant="outlined" size="small"
                                    disabled={candidateSignUpStatus === 'pending' || isSubmitDisabled || !selectedResume}
                                    onClick={() => dispatch(signUpCandidate({ payload: candidateSignUpPayload }))}>
                                    {(candidateSignUpStatus === 'pending') ? 'Signing up...' : 'Sign up'}
                                </ShGreenBtn>
                            </>}
                    </Stack>
                    {CandidatePrivacyPolicyStatement}
                </Stack>
            </Paper>
        </Container>
    </>)
}