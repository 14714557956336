import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Menu } from '@mui/material';
import { ApplicantStages } from 'Modules/Core/Applicants/ApplicantsConstants';
import { IStagesTogglerProps, JobStages, TInterviewStage } from 'Modules/Core/Applicants/ApplicantsModel';
import { useNotification } from 'Modules/Core/Notification';
import { useAppDispatch } from 'helpers/hooks';
import { MouseEvent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ConfirmationDialog } from 'shared/SharedComponents/ConfirmationDialog/ConfirmationDialog';
import { ShButton } from 'shared/SharedStyles/ShInputs';
import { StyledMenuItem } from 'shared/SharedStyles/ShNavigation';
import { resetChangeApplicantStageInProfile } from 'store/slices/employer/applicants/applicant-profile-slice';
import { resetStageChange } from 'store/slices/employer/applicants/applicants-list-slice';
import { openInterviewDialog } from 'store/slices/employer/interviews/interviews-shared-slice';
import { RejectionEmailDialog } from './RejectionEmailDialog';

export const StagesToggler = ({ applicant, changeStage, className, includeOnlyInterviews, usedIn }: IStagesTogglerProps) => {
    const notification = useNotification();
    const dispatch = useAppDispatch();
    const { jobId } = useParams();
    /**
       * Using map with Applicant id as key and element ref as value since the menu's ref is present in multiple locations opening one
       * menu toggles multiple menus to open. With the help of this map toggling menu for appropriate element is possible.
    */
    const [stagesAnchorEl, setStagesAnchorEl] = useState<{ [key: number]: HTMLElement | null }>({ 0: null });
    const [_isRejectionEmailDialogOpen, _setIsRejectionEmailDialogOpen] = useState<{ [key: number]: boolean }>();
    const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState<{ [key: number]: boolean }>();

    const openStagesMenu = (e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>, indx: number) => {
        e.stopPropagation();
        setStagesAnchorEl({ ...stagesAnchorEl, [indx]: e.currentTarget });
    };

    const _changeStage = (e: MouseEvent<HTMLLIElement, globalThis.MouseEvent>, stg: Lowercase<TInterviewStage>) => {
        e.stopPropagation();
        setStagesAnchorEl({ ...stagesAnchorEl, [applicant?.candidateEmployerJobId]: null });
        if (stg === 'interview' || stg === 'second_interview') {
            dispatch(openInterviewDialog({
                isInterviewDialogOpen: true,
                applicantInfo: applicant,
                candidateName: undefined,
                interviewId: undefined,
                jobId: parseInt(jobId ?? ''),
                candidateEmployerJobId: applicant.candidateEmployerJobId,
                stage: stg,
                usedIn: usedIn
            }))
        } else if (stg === 'rejected') {
            setIsConfirmDialogOpen({ [applicant.candidateEmployerJobId]: true });
        } else {
            changeStage(stg, applicant?.candidateEmployerJobId);
        }
    };

    /**
     * Transforms stage code into appropriate stage label to be displayed in the button.
     * */
    const getTransformedLabel = (stage: TInterviewStage): string => {
        return stage?.split('_').map(str => str[0].toUpperCase() + str.substring(1, str.length).toLowerCase()).join(' ');
    };

    const closeRejectEmailConfirmation = (e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
        e.stopPropagation();
        setIsConfirmDialogOpen({ [applicant.candidateEmployerJobId]: false });
        changeStage('rejected', applicant?.candidateEmployerJobId);
    }

    const onRejectEmailDialogConfirm = (e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
        e.stopPropagation();
        setIsConfirmDialogOpen({ [applicant.candidateEmployerJobId]: false });
        _setIsRejectionEmailDialogOpen({ [applicant.candidateEmployerJobId]: true });
    }

    // Show snackbar notifications when the stage change is either successful or failed.
    useEffect(() => {
        if (applicant?.changeStateStatus === 'failed' || applicant?.changeStateStatus === 'success') {
            notification.displayNotification({
                open: true,
                type: applicant?.changeStateStatus === 'failed' ? 'error' : 'success',
                message: applicant?.changeStateResponse ?? ''
            });
            dispatch(resetStageChange(applicant?.candidateEmployerJobId));
            dispatch(resetChangeApplicantStageInProfile());
        }
    }, [applicant?.candidateEmployerJobId, applicant?.changeStateResponse, applicant?.changeStateStatus, dispatch, notification]);

    return (<>
        <ShButton className={className} size='small' aria-label={getTransformedLabel(applicant?.jobStage)} variant="outlined"
            disabled={applicant?.changeStateStatus === 'pending'}
            color={
                includeOnlyInterviews ? 'info' :
                    applicant?.jobStage?.toUpperCase() === JobStages.Interview ? 'primary' :
                        (applicant?.jobStage?.toUpperCase() === JobStages.Hired ||
                            applicant?.jobStage?.toUpperCase() === JobStages.Offer) ? 'success' :
                            applicant?.jobStage?.toUpperCase() === JobStages.Rejected ? 'error' :
                                applicant?.jobStage?.toUpperCase() === JobStages.Phone_Screening ? 'secondary' : 'warning'}
            endIcon={<ExpandMoreIcon />} onClick={e => { openStagesMenu(e, applicant?.candidateEmployerJobId) }}>
            {includeOnlyInterviews
                ? 'Schedule Interview' :
                (applicant?.changeStateStatus === 'pending' ? 'Updating Stage...' : getTransformedLabel(applicant?.jobStage))
            }
        </ShButton>
        {stagesAnchorEl[applicant?.candidateEmployerJobId] && <Menu id={'stage_menu' + applicant?.candidateEmployerJobId}
            aria-label={applicant?.jobStage} anchorEl={stagesAnchorEl[applicant?.candidateEmployerJobId]}
            open={stagesAnchorEl[applicant?.candidateEmployerJobId] !== null}
            onClose={() => setStagesAnchorEl({ ...stagesAnchorEl, [applicant?.candidateEmployerJobId]: null })}
            onClick={e => { setStagesAnchorEl({ ...stagesAnchorEl, [applicant?.candidateEmployerJobId]: null }); e.stopPropagation() }}>
            {includeOnlyInterviews ? (
                ApplicantStages.filter((stage) => stage.value.toLowerCase().includes("interview") ||
                    stage.value.toLowerCase().includes("phone")).map((stage) => (
                        <StyledMenuItem key={stage.value} disabled={stage.value === 'auto_rejected'}
                            className={stage.value === applicant?.jobStage?.toLowerCase() ? "selected-item" : ""}
                            onClick={(e) => _changeStage(e, stage.value)}>{stage.label}</StyledMenuItem>
                    ))
            ) : (
                ApplicantStages.map(stage => (
                    <StyledMenuItem key={stage.value} disabled={stage.value === 'auto_rejected'}
                        className={stage.value === applicant?.jobStage?.toLowerCase() ? "selected-item" : ""}
                        onClick={e => stage.value !== applicant?.jobStage?.toLowerCase() &&
                            _changeStage(e, stage.value)}>{stage.label}</StyledMenuItem>
                )))}
        </Menu>}
        {isConfirmDialogOpen && isConfirmDialogOpen[applicant.candidateEmployerJobId] &&
            <ConfirmationDialog contentText={<>
                Sending this email will notify <strong>{applicant.fullName ?? ''}
                </strong> that their application has been rejected. Are you sure you want to proceed?
            </>}
                title={`Do you want to email Rejection Letter/Note ${applicant.fullName ? ' to ' + applicant.fullName + '?' : '?'}`}
                isDialogOpen={isConfirmDialogOpen[applicant.candidateEmployerJobId]} isShowCloseIcon
                onDialogClose={() => setIsConfirmDialogOpen({ [applicant.candidateEmployerJobId]: false })}
                onCancel={(e) => closeRejectEmailConfirmation(e)} actionsPlacement='flex-end'
                onConfirm={(e) => onRejectEmailDialogConfirm(e)} confirmButtonLabel={'Yes'} cancelButtonLabel={'No'} />
        }
        {jobId && _isRejectionEmailDialogOpen &&
            <RejectionEmailDialog applicant={applicant}
                isRejectionEmailDialogOpen={_isRejectionEmailDialogOpen[applicant.candidateEmployerJobId]} jobId={parseInt(jobId)}
                setIsRejectionEmailDialogOpen={_setIsRejectionEmailDialogOpen} />}
    </>);
};


