import ArticleIcon from '@mui/icons-material/Article';
import NearMeIcon from '@mui/icons-material/NearMe';
import SearchIcon from '@mui/icons-material/Search';
import { Grid, Grow, InputAdornment, Stack, TextField, Typography, darken } from "@mui/material";
import { GoogleMcIcon } from "assets/Icons";
import { IsSmScreen } from "helpers/hooks";
import { ChangeEvent, useState } from "react";
import { SiGlassdoor, SiIndeed, SiMonster } from "react-icons/si";
import { Link as RouterLink } from 'react-router-dom';
import { LinkedinIcon } from "react-share";
import { ShWordpressButton } from "shared/SharedStyles/ShInputs";
import { ShContainer } from "shared/SharedStyles/ShLayouts";
import { ShMuiLink } from "shared/SharedStyles/ShNavigation";
import { ShPaper } from "shared/SharedStyles/ShSurfaces";
import { PrimaryThemeColor, PrimaryWordpressThemeColor, ShGreen } from "shared/SharedStyles/styleConstants";
import { OfferTemplateHomeStack, ResourceElementsBorderRadius, ResourceTypography } from "../../Resources.styled";
import { offerTemplates } from "./OfferTemplateConstants";
import { Helmet } from 'react-helmet';
import { SHSignUpLink } from 'shared/constants';

export const OfferTemplateHome = () => {

    const isSmScreen = IsSmScreen();
    const [searchQuery, setSearchQuery] = useState('');
    const filteredTemplates = offerTemplates.filter(template =>
        template.title.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(event.target.value);
    };


    return (
        <>
        <Helmet>
            <title>Free Offer Letter Templates | SmoothHiring</title>
            <meta name="description" content="Find customizable offer letter templates to streamline your hiring process. Download professionally crafted templates for free at SmoothHiring"/>
        </Helmet>
        <ShContainer maxWidth="xl" height="100%" margin="auto">
            <Stack paddingBottom={3} paddingTop={3}>
                <ShPaper elevation={4} borderRadius={ResourceElementsBorderRadius}>
                    <Grow in={true} timeout={1000} mountOnEnter unmountOnExit>
                        <Stack alignItems='center' justifyContent='center' padding={isSmScreen ? 0 : 2}>
                            <ArticleIcon color="primary" />
                            <Typography paddingTop={1} color={darken(PrimaryWordpressThemeColor, 0.1)} textAlign="center" gutterBottom variant="body2" fontWeight={700}>
                                HR Templates | Offer Templates
                            </Typography>
                            {isSmScreen ?
                                <Typography component ={ 'h1'} textAlign="center" gutterBottom fontSize={20} fontWeight={700}>
                                    Job Offer Templates
                                </Typography>
                                :
                                <Typography component = {'h1'} textAlign="center" gutterBottom fontSize={30} fontWeight={700}>
                                    Job Offer Templates
                                </Typography>

                            }
                            {isSmScreen ?
                                <Typography textAlign="center" fontSize={15} >
                                    Job offer templates are standardized documents used by companies to extend employment offers to prospective candidates. These templates serve as a blueprint, outlining the key components of an employment offer, ensuring consistency, compliance, and efficiency throughout the hiring process.</Typography>
                                :
                                <Typography textAlign="center" fontSize={18} >
                                    Job offer templates are standardized documents used by companies to extend employment offers to prospective candidates. These templates serve as a blueprint, outlining the key components of an employment offer, ensuring consistency, compliance, and efficiency throughout the hiring process.</Typography>
                            }
                        </Stack>
                    </Grow>
                    <Stack padding={3} margin={'auto'} maxWidth="sm" paddingBottom={3} direction="row">
                        <TextField label="Search Templates" variant="outlined" fullWidth onChange={handleSearchChange}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Stack>
                </ShPaper>
            </Stack>
            <Grid paddingBottom={3} container spacing={2}>
                {filteredTemplates.map((template, index) => (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={index} >
                        <ShMuiLink noUnderline component={RouterLink} to={`/resources/offer-letter-templates${template.path}`}>
                            <OfferTemplateHomeStack color={darken(PrimaryWordpressThemeColor, 0.3)} minHeight={isSmScreen ? '100px' : '150px'} justifyContent="center">
                                <Typography textAlign="left" fontWeight="bold" fontSize={isSmScreen ? 17 : 19}>
                                    {template.title}
                                </Typography>
                                <Typography textAlign="left" fontSize={isSmScreen ? 11 : 13}>
                                    {template.description}
                                </Typography>
                            </OfferTemplateHomeStack>
                        </ShMuiLink>
                    </Grid>
                ))}
            </Grid>
            <Stack paddingBottom={3} margin='auto' maxWidth='lg'>
                <ShPaper elevation={3} >
                    <Stack padding={2} color='black' spacing={2}>
                        <ResourceTypography variant="h6" fontWeight="bold">
                            Ready to post your job to 100's of job boards?
                        </ResourceTypography>
                        <ResourceTypography variant="subtitle1">
                            LinkedIn, Talent.com, career page - place your job on multiple platforms for FREE with just one click
                        </ResourceTypography>
                        <Stack direction='row' spacing={2} alignItems="center">
                            <SiIndeed size={20} color={PrimaryThemeColor} />
                            <LinkedinIcon size={20} />
                            <GoogleMcIcon />
                            <SiMonster size={20} color={PrimaryWordpressThemeColor} />
                            <SiGlassdoor size={20} color={ShGreen} />
                        </Stack>
                        <ShWordpressButton href={SHSignUpLink} size="large" color="primary" variant="contained" endIcon={<NearMeIcon />}>
                            <Typography fontWeight="bold">Post a Free Job &nbsp;</Typography>
                        </ShWordpressButton>
                    </Stack>
                </ShPaper>
            </Stack>
        </ShContainer>
        </>
    );
};

export default OfferTemplateHome;
