import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
    ICandidateDetails, ICandidateJobApplication, ICandidateProfileState,
    ICandidateSignUpPayload,
    ICurrentAppliedJob
} from "Modules/Core/Candidate/CandidateModel";
import { AxiosResponse } from "axios";
import httpAdapterInstance from "configs/HttpAdapterConfig";
import { PURGE } from "redux-persist";
import { EmployerApiEndpoints } from "shared/ApiEndpoints";
import { IBaseResponse, IFailureResponse } from "shared/SharedModels";
import { DefaultAPIErrorMsg } from "shared/constants";
import { TSocialLoginProvider } from "../auth-v2/auth-v2-model";

const initialCandidateProfileState: ICandidateProfileState = { currentJobsApplied: [] };

export const signUpCandidate = createAsyncThunk<{ authToken: string, message: string },
    { payload: ICandidateSignUpPayload }, { rejectValue: (IFailureResponse & { signup_source: TSocialLoginProvider }) }>(
        "signUpCandidate",
        async ({ payload }, { rejectWithValue }) => {
            return await httpAdapterInstance
                .post(`${EmployerApiEndpoints.CANDIDATE_SIGN_UP}`, payload)
                .then((response: AxiosResponse<{ authToken: string, message: string }>) => response?.data)
                .catch((error) => {
                    throw rejectWithValue(error.response.data);
                });
        }
    );

export const getCandidateApplicationHistory = createAsyncThunk<IBaseResponse<ICandidateJobApplication[]>, void, { rejectValue: IBaseResponse }>(
    "getCandidateApplicationHistory",
    async (_, { rejectWithValue }) => {
        return await httpAdapterInstance
            .get(`${EmployerApiEndpoints.CANDIDATE_JOB_APPLICATION_HISTORY}`)
            .then((response: AxiosResponse<IBaseResponse<ICandidateJobApplication[]>>) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

export const getCandidateDetails = createAsyncThunk<IBaseResponse<ICandidateDetails>,
    { candidateId: number }, { rejectValue: IBaseResponse }>(
        "getCandidateDetails",
        async ({ candidateId }, { rejectWithValue }) => {
            return await httpAdapterInstance
                .get(`${EmployerApiEndpoints.CANDIDATE_DETAILS}/${candidateId}`)
                .then((response: AxiosResponse<IBaseResponse<ICandidateDetails>>) => response?.data)
                .catch((error) => {
                    throw rejectWithValue(error.response.data);
                });
        }
    );

export const getCandidateCareerReport = createAsyncThunk<string, { applicantId: number; }, { rejectValue: IBaseResponse; }>(
    'getCandidateCareerReport',
    async ({ applicantId }, { rejectWithValue }) => {
        return await httpAdapterInstance
            .get(`${EmployerApiEndpoints.CAREER_REPORT}/${applicantId}`, { responseType: 'blob' })
            .then((response: AxiosResponse<Blob>) => {
                const blobData = response.data;

                // Convert Blob to Base64 string
                const reader = new FileReader();
                reader.readAsDataURL(blobData);
                return new Promise<string>((resolve, reject) => {
                    reader.onloadend = () => {
                        const base64data = reader.result as string;
                        resolve(base64data);
                    };
                });
            })
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

export const getCandidateCurrentAppliedJobs = createAsyncThunk<IBaseResponse<ICurrentAppliedJob[]>,
    { candidateId: number }, { rejectValue: IBaseResponse }>(
        "getCandidateCurrentAppliedJobs",
        async ({ candidateId }, { rejectWithValue }) => {
            return await httpAdapterInstance
                .get(`${EmployerApiEndpoints.CANDIDATE_CURRENT_JOBS}/${candidateId}`)
                .then((response: AxiosResponse<IBaseResponse<ICurrentAppliedJob[]>>) => response?.data)
                .catch((error) => {
                    throw rejectWithValue(error.response.data);
                });
        }
    );

const candidateProfileSlice = createSlice({
    name: "candidateProfile",
    initialState: initialCandidateProfileState,
    reducers: {
        resetSignUpCandidate: (state) => {
            state.candidateSignUpStatus = 'idle';
            state.candidateSignUpResponse = ''
        },
        resetGetCandidateApplicationHistory: (state) => {
            state.getCandidateHistoryStatus = 'idle';
            state.getCandidateHistoryResponse = ''
        },
        setCandidateAuthToken: (state, action: PayloadAction<{ authToken: string }>) => {
            state.candidateAuthToken = action.payload.authToken;
        },
        resetGetCandidateDetails: (state) => {
            state.getCandidateDetailsStatus = 'idle';
            state.getCandidateDetailsResponse = '';
            state.candidateDetails = undefined;
        },
        resetCareerReport: (state) => {
            state.getApplicantCareerReportStatus = 'idle';
            state.getApplicantCareerReportResponse = '';
            state.careerReport = undefined;
        },
        resetGetCandidateCurrentAppliedJobs: (state) => {
            state.getCurrentJobsAppliedJobsStatus = 'idle';
            state.getCurrentJobsAppliedJobsResponse = '';
            state.currentJobsApplied = [];
        }
    },
    extraReducers: (builder) => {
        // On Store PURGE reset the state
        builder.addCase(PURGE, () => {
            return initialCandidateProfileState;
        });
        // sign up candidate
        builder.addCase(signUpCandidate.pending, (state) => {
            state.candidateSignUpStatus = 'pending'
        });
        builder.addCase(signUpCandidate.fulfilled, (state, action) => {
            state.candidateSignUpStatus = 'success';
            state.candidateAuthToken = action.payload?.authToken;
        });
        builder.addCase(signUpCandidate.rejected, (state, action) => {
            state.candidateSignUpStatus = 'failed';
            state.candidateSignUpResponse = action?.payload?.message ?? DefaultAPIErrorMsg;
            state.candidateSignUpErrorCode = action.payload?.errorCode;
            state.socialLoginProvider = action.payload?.signup_source;
        });
        //get candidate history
        builder.addCase(getCandidateApplicationHistory.pending, (state) => {
            state.getCandidateHistoryStatus = 'pending'
        });
        builder.addCase(getCandidateApplicationHistory.fulfilled, (state, action) => {
            state.getCandidateHistoryStatus = 'success';
            state.candidateJobApplicationHistory = action.payload?.data;
        });
        builder.addCase(getCandidateApplicationHistory.rejected, (state, action) => {
            state.getCandidateHistoryStatus = 'failed';
            state.getCandidateHistoryResponse = action?.payload?.message ?? DefaultAPIErrorMsg;
        });
        // get candidate details
        builder.addCase(getCandidateDetails.pending, (state) => {
            state.getCandidateDetailsStatus = 'pending';
        });
        builder.addCase(getCandidateDetails.fulfilled, (state, action) => {
            state.getCandidateDetailsStatus = 'success';
            state.candidateDetails = action.payload.data;
        });
        builder.addCase(getCandidateDetails.rejected, (state, action) => {
            state.getCandidateDetailsStatus = 'failed';
            state.getCandidateDetailsResponse = action?.payload?.message ?? DefaultAPIErrorMsg;
        });
        // get career reports
        builder.addCase(getCandidateCareerReport.pending, (state) => {
            state.getApplicantCareerReportStatus = 'pending';
        });
        builder.addCase(getCandidateCareerReport.fulfilled, (state, action) => {
            state.getApplicantCareerReportStatus = 'success';
            state.careerReport = action.payload;
        });
        builder.addCase(getCandidateCareerReport.rejected, (state, action) => {
            state.getApplicantCareerReportStatus = 'failed';
            state.getApplicantCareerReportResponse = action?.payload?.message ?? DefaultAPIErrorMsg;
        });
        // get current jobs
        builder.addCase(getCandidateCurrentAppliedJobs.pending, (state) => {
            state.getCurrentJobsAppliedJobsStatus = 'pending';
        });
        builder.addCase(getCandidateCurrentAppliedJobs.fulfilled, (state, action) => {
            state.getCurrentJobsAppliedJobsStatus = 'success';
            state.currentJobsApplied = action.payload.data;
        });
        builder.addCase(getCandidateCurrentAppliedJobs.rejected, (state, action) => {
            state.getCurrentJobsAppliedJobsStatus = 'failed';
            state.getCurrentJobsAppliedJobsResponse = action?.payload?.message ?? DefaultAPIErrorMsg;
        });
    }
});

export const { resetSignUpCandidate, resetGetCandidateApplicationHistory, setCandidateAuthToken,
    resetGetCandidateCurrentAppliedJobs, resetGetCandidateDetails } = candidateProfileSlice.actions;
export default candidateProfileSlice;
