import NearMeIcon from '@mui/icons-material/NearMe';
import { Box, FormControl, Grid, Grow, InputLabel, MenuItem, Select, Stack, Typography } from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";
import { IsSmScreen } from "helpers/hooks";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ShButton, ShTextField, ShWordpressButton } from "shared/SharedStyles/ShInputs";
import { ShContainer } from "shared/SharedStyles/ShLayouts";
import { PrimaryThemeColor, PrimaryWordpressThemeColor } from "shared/SharedStyles/styleConstants";
import { getResourcesRedirect } from 'shared/utils';
import { ResourceHomeTextField, ResourceTypography, TemplateHomesStack } from '../../Resources.styled';
import { JobDescriptions as templateDescriptions } from "./DescriptionTemplateConstants";
import { Helmet } from 'react-helmet';
import { SHSignUpLink } from 'shared/constants';

export const DescriptionTemplateHome = () => {
    const navigate = useNavigate();
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [selectedCategory, setSelectedCategory] = useState<string>('');
    const isVisible = true;
    const isSmScreen = IsSmScreen();


    const truncateText = (text: string, maxLength: number): string => {
        if (text.length <= maxLength) {
            return text;
        }
        return text.slice(0, maxLength) + '...';
    };

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(event.target.value.toLowerCase());
    };

    const handleCategoryChange = (event: SelectChangeEvent<string>) => {
        setSelectedCategory(event.target.value as string);
    };

    const filteredJobDescriptions = Object.entries(templateDescriptions).map(([category, descriptions]) => {
        const filteredDescriptions = descriptions.filter(description =>
            description.toLowerCase().includes(searchQuery)
        );
        return { category, descriptions: filteredDescriptions };
    }).filter(({ category, descriptions }) =>
        descriptions.length > 0 && (selectedCategory === '' || category === selectedCategory)
    );

    const sortedJobDescriptions = filteredJobDescriptions.sort((a, b) => a.category.localeCompare(b.category));

    const handleButtonClick = (description: string) => {
        const formattedTemplateTitle = description.toLowerCase().replace(/ /g, '-');
        navigate(`${getResourcesRedirect('jobTemplatesHome')}/${formattedTemplateTitle}`);
    };

    return (
        <>
        <Helmet>
            <title>Free Job Description Templates | SmoothHiring</title>
            <meta name="description" content="Get customizable job description templates to help you hire more quickly. Use SmoothHiring's assistance to create compelling job advertisements."/>
        </Helmet>
        <ShContainer maxWidth="xl" height="100%" margin="auto">
            <TemplateHomesStack marginBottom={4} marginTop={4} >
                <Grow in={isVisible} timeout={1000} mountOnEnter unmountOnExit>
                    <Stack justifyContent='center' padding={isSmScreen ? 0 : 2}>
                        <Typography textAlign="center" gutterBottom variant="body2" fontWeight={700} color={PrimaryThemeColor}>
                            HR Templates | Job descriptions
                        </Typography>
                        {isSmScreen ?
                            <Typography component={'h1'} textAlign="center" gutterBottom fontSize={20} fontWeight={700}>
                                Job Description Templates
                            </Typography>
                            :
                            <Typography component={'h1'} textAlign="center" gutterBottom fontSize={30} fontWeight={700}>
                                Job Description Templates
                            </Typography>

                        }
                        {isSmScreen ?
                            <Typography textAlign="center" fontSize={15} >
                                Crafted to enhance visibility and optimize for job board approval and SEO, our library of over 500+ job description templates ensures heightened exposure and expedites the hiring process. Enriched with tailored content, these descriptions attract top-tier candidates and facilitate the influx of qualified applicants.</Typography>
                            :
                            <Typography textAlign="center" fontSize={18} >
                                Crafted to enhance visibility and optimize for job board approval and SEO, our library of over 500+ job description templates ensures heightened exposure and expedites the hiring process. Enriched with tailored content, these descriptions attract top-tier candidates and facilitate the influx of qualified applicants.</Typography>
                        }


                        <Stack justifyContent='center' margin={5} spacing={2} direction={isSmScreen ? 'column' : 'row'}>
                            <ResourceHomeTextField label='Enter Job Title' variant="standard" />
                            <ShButton href={SHSignUpLink} size='large' color="primary" variant='contained' endIcon={<NearMeIcon />}>
                                <Typography minWidth='max-content'>Post this Job &nbsp;</Typography>
                            </ShButton>
                        </Stack>


                    </Stack>
                </Grow>
            </TemplateHomesStack>
            <Stack direction={isSmScreen ? 'column' : 'row'} spacing={3} marginBottom={4} >
                <Box display="flex" minWidth={330}>
                    <ShTextField
                        label="Search Job Description Templates"
                        variant="outlined"
                        value={searchQuery}
                        onChange={handleSearchChange}
                        fullWidth
                        size="medium"
                    />
                </Box>
                <FormControl variant="outlined" sx={{ minWidth: 330 }}>
                    <InputLabel id="category-select-label">Filter by Category</InputLabel>

                    <Select labelId="category-select-label" id="category-select" value={selectedCategory} onChange={handleCategoryChange} label="Filter by Category">
                        <MenuItem value="">All Categories</MenuItem>
                        {Object.keys(templateDescriptions).map((category) => (
                            <MenuItem key={category} value={category}>{category}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Stack>

            {sortedJobDescriptions.map(({ category, descriptions }) => (
                <Box key={category} marginBottom={5}>
                    <Typography padding={1} paddingBottom={2} variant="h5" fontWeight={700} gutterBottom color={PrimaryThemeColor}>
                        {category.replace("_", " ")}
                    </Typography>
                    <Grid container spacing={1.5}>
                        {descriptions.map((description, index) => (
                            <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                                <ShButton color="inherit" onClick={() => handleButtonClick(description)}>
                                    <Typography textAlign='left' variant="body2">{truncateText(description, 43)}</Typography>
                                </ShButton>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            ))}
            <ShContainer margin='auto' maxWidth='lg'>
                <TemplateHomesStack color='white' spacing={2} marginBottom={10} backgroundColor={PrimaryWordpressThemeColor}>
                    <ResourceTypography gutterBottom variant="h6" fontWeight="bold">
                        Ready to post your job to 100's of job boards?
                    </ResourceTypography>
                    <ResourceTypography gutterBottom variant="subtitle1">
                        LinkedIn, Talent.com, career page - place your job on multiple platforms for FREE with just one click
                    </ResourceTypography>
                    <ShWordpressButton href={SHSignUpLink} size="large" color="primary" variant="contained" endIcon={<NearMeIcon />}>
                        <Typography fontWeight="bold">Post a Free Job &nbsp;</Typography>
                    </ShWordpressButton>
                </TemplateHomesStack>
            </ShContainer>
        </ShContainer>
        </>
    );
};

export default DescriptionTemplateHome;
