import { TAccountType } from "store/slices/auth-v2/auth-v2-model";
import { ISocialProvider, TFeaturesMap, TRoleAccessMap, TSocialProvidersMap } from "./AuthModel";

export const EmployerLoginRedirect = '/employer/dashboard';
export const EmployerLogoutRedirect = '/accounts/login';
export const AdminLoginRedirect = "/admin";
export const AdminLogoutRedirect = "/accounts/login";
export const CandidateLoginRedirect = "/candidate/home";
export const CandidateSurveyLogoutRedirect = "/candidate/login";
export const UnauthorizedRedirect = "/employer/unauthorized";
export const CandidateLogoutRedirect = "/candidate/apply-job";
export const DefaultRoleForRAC: TAccountType = 'employer';
export const SessionExpiredTimer = 30;

const HiringMangerAndObserverBlockedPaths = [
    '/employer/job/new',
    '/employer/job/new/title',
    '/employer/job/:jobId/title',
    '/employer/job/:jobId/details',
    '/employer/job/:jobId/preview',
    '/employer/job/:jobId/bundles',
    '/employer/job/:jobId/payment',
    '/employer/job/:jobId/payment/success',
    '/employer/job/:jobId/payment/failed',
    '/employer/settings/company-information',
    '/employer/settings/access-levels'
];

const HiringMangerAndObserverBlockedFeatures: Partial<TFeaturesMap> = {
    'Create Job': {
        visibility: 'disabled'
    },
    'Company Details': {
        visibility: 'hidden'
    },
    'Manage Users': {
        visibility: 'hidden'
    },
    'Start Hiring': {
        visibility: 'disabled'
    },
    'Stop Hiring': {
        visibility: 'disabled'
    },
    'Edit Job': {
        visibility: 'disabled'
    },
    'Delete Job': {
        visibility: 'disabled'
    },
    'Re-post Job': {
        visibility: 'disabled'
    },
    'Template Jobs': {
        visibility: 'hidden'
    }
}

const _testFeatureMap: Partial<TFeaturesMap> = {
    // 'Template Jobs': { visibility: 'hidden' },
    // 'Create Template Job': { visibility: 'hidden' },
    // // 'Use Template Job': { visibility: 'hidden' }
}

export const TemplateJobPaths = [
    '/employer/jobs/template',
    '/employer/job/new/template-job/choose-type'
];

export const TemplateJobFeatureMap: Partial<TFeaturesMap> = {
    'Template Jobs': {
        visibility: 'hidden'
    },
    'Use Template Job': {
        visibility: 'hidden'
    },
    'Create Template Job': {
        visibility: 'hidden'
    }
}

/**
* Role based access control map used to identify the urls, features that are blocked for the current account's role.
*/
export const RoleAccessControlMap: TRoleAccessMap = {
    admin: { paths: [], featureMap: { ..._testFeatureMap } },
    candidate: { paths: [], featureMap: { ..._testFeatureMap } },
    employer: { paths: [], featureMap: { ..._testFeatureMap } },
    hiring_manager: {
        paths: [...HiringMangerAndObserverBlockedPaths, ...TemplateJobPaths],
        featureMap: { ...HiringMangerAndObserverBlockedFeatures, ...TemplateJobFeatureMap }
    },
    observer: {
        paths: [...HiringMangerAndObserverBlockedPaths, ...TemplateJobPaths],
        featureMap: { ...HiringMangerAndObserverBlockedFeatures, ...TemplateJobFeatureMap }
    },
    owner: { paths: [], featureMap: { ..._testFeatureMap } },
    recruiter: {
        paths: [],
        featureMap: {
            'Company Details': {
                visibility: 'hidden'
            },
            'Manage Users': {
                visibility: 'hidden'
            }
        }
    }
};

export const SocialProvidersMap: TSocialProvidersMap = {
    facebook: 'Facebook',
    google: 'Google',
    linkedin: 'LinkedIn',
    outlook: 'Outlook'
};

export const SocialProviders: ISocialProvider[] = [
    { displayName: 'Google', socialProviderKey: 'google' },
    { displayName: 'Facebook', socialProviderKey: 'facebook' },
    { displayName: 'LinkedIn', socialProviderKey: 'linkedin' },
    //  Outlook sign in is pending from the api. To be uncommented once api is done
    // { displayName: 'Outlook', socialProviderKey: 'outlook' }
];