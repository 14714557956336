import PersonIcon from '@mui/icons-material/Person';
import { Avatar, Box, Container, Stack, Typography } from "@mui/material";
import { useAppSelector } from "helpers/hooks";
import { Link as RouterLink } from 'react-router-dom';
import { ShMuiLink } from 'shared/SharedStyles/ShNavigation';
import { ShPaper } from 'shared/SharedStyles/ShSurfaces';
import { PrimaryThemeColor } from "shared/SharedStyles/styleConstants";
import { CandidateDashboardTiles } from './CandidateConstants';

export const CandidateHome = () => {

    const { firstName } = useAppSelector(state => state.auth.auth);

    return (<>
        <Container maxWidth='md'>
            {firstName &&
                <Stack padding={{ xs: 1, sm: 2, md: 3, lg: 4 }} direction='row' alignItems='center' columnGap={1}>
                    <Avatar>{firstName ? firstName[0] : <PersonIcon />}</Avatar>
                    <Stack direction='row' alignItems='center' columnGap={1}>
                        <Typography variant="h4">{`Welcome ${firstName ? `back,` : '!'}`}</Typography>
                        <Typography variant="h4" color={PrimaryThemeColor}>{firstName}</Typography>
                    </Stack>
                </Stack>}
            <Box padding={{ xs: 1, sm: 2, md: 3, lg: 4 }}>
                <Typography variant='h6' color={PrimaryThemeColor} gutterBottom>Career Seeker Resources</Typography>
                <Stack rowGap={{ xs: 1, sm: 2, md: 2, lg: 2 }}>
                    {CandidateDashboardTiles.map(candidateTile => (
                        <ShPaper key={candidateTile.tileLabel} transElevateOnHover cursor='pointer' noPadding>
                            <ShMuiLink padding={2} component={RouterLink} display='flex' alignItems='center' width='100%'
                                columnGap={1} to={candidateTile.featureRedirectUrl} noUnderline color='inherit'>
                                {candidateTile.tileIcon}
                                <Box>
                                    <Typography variant='body1'>{candidateTile.tileLabel}</Typography>
                                    <Typography variant='caption'>{candidateTile.caption}</Typography>
                                </Box>
                            </ShMuiLink>
                        </ShPaper>
                    ))}
                </Stack>
            </Box>
        </Container>
    </>)
}