import { Card, CardActions, CardContent, CircularProgress, Stack, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "helpers/hooks";
import { useLocation, useNavigate } from "react-router-dom";
import { ShBackdrop } from "shared/SharedStyles/ShFeedback";
import { ShButton } from "shared/SharedStyles/ShInputs";
import { logoutAccount } from "store/slices/auth-v2/auth-v2-slice";
import { ISocialLoader } from "./AuthModel";

export const SocialLoader = ({ authProvider }: ISocialLoader) => {

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const location = useLocation();
    // Potential error cases.
    const errCode = new URLSearchParams(location.search)?.get('error_code');
    const errMsg = new URLSearchParams(location.search)?.get('error_message');
    const urlState = new URLSearchParams(useLocation().search)?.get('state');
    const { employerSocialProfileStatus, signUpWithCandidateSocialProfileStatus,
        employerSocialProfileResponse } = useAppSelector((state) => state.auth.socialLogin);
    const { currentAppContext } = useAppSelector((state) => state.auth.auth);

    const cancelSocial = () => {
        dispatch(logoutAccount);
        if (currentAppContext === 'candidate_apply_job') {
            if (urlState) {
                let redirectObj: { ps: string, jobCode: string };
                redirectObj = JSON.parse(decodeURIComponent(urlState));
                navigate(`/candidate/apply-job/${redirectObj?.jobCode}${redirectObj.ps ? ('?ps=' + redirectObj.ps) : ''}`);
            }
        } else {
            navigate('/accounts/login');
        }
    }

    return (<>
        <ShBackdrop open={true}>
            <Card>
                <CardContent>
                    {errCode || errMsg ? <Typography gutterBottom>{errMsg}</Typography> : <>
                        <Stack>
                            <Typography variant="body2" gutterBottom>
                                {employerSocialProfileStatus === 'pending' && `Please wait - Logging in`}
                                {signUpWithCandidateSocialProfileStatus === 'pending' && `Signing up in with ${authProvider}`}
                            </Typography>
                            {(employerSocialProfileStatus === 'pending' ||
                                signUpWithCandidateSocialProfileStatus === 'pending') && <CircularProgress color="inherit" />}
                            {employerSocialProfileStatus === 'failed' && <Typography variant="body2">
                                {employerSocialProfileResponse}
                            </Typography>}
                        </Stack>
                    </>}
                </CardContent>
                {employerSocialProfileStatus !== 'pending' && <CardActions>
                    <ShButton size="small" onClick={cancelSocial}>Back</ShButton>
                </CardActions>}
            </Card>
        </ShBackdrop>
    </>)
}