import React from 'react';
import { Grid } from '@mui/material';
import { IWidget } from '../DashboardModel';
import { WidgetsConfig } from './WidgetFactoryConfig';


const WidgetFactory: React.FC = () => {

    const rows: { [key: number]: IWidget[] } = {};

    // Organize widgets by rows
    WidgetsConfig.forEach((widget: IWidget) => {
        if (!rows[widget.row]) {
            rows[widget.row] = [];
        }
        rows[widget.row].push(widget);
    });

    return (
        <React.Fragment>
            {Object.keys(rows).map((row) => (
                <Grid container spacing={3} key={`row-${row}`}>
                    {rows[parseInt(row)].map((widget: IWidget) => {
                            const WidgetComponent = React.memo(widget.component);

                        return (
                            <Grid item key={widget.id} {...widget.gridProps} columnGap={1} rowGap={1}>
                                <WidgetComponent  />
                            </Grid>
                        );
                    })}
                </Grid>
            ))}
        </React.Fragment>
    );
};

export default WidgetFactory;
