import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import httpAdapterInstance from "configs/HttpAdapterConfig";
import { PURGE } from "redux-persist";
import { EmployerApiEndpoints } from "shared/ApiEndpoints";
import { DefaultAPIErrorMsg } from "shared/constants";
import { IBaseResponse } from "shared/SharedModels";
import { IGetResetPasswordPayload, IPasswordState, IResetPasswordPayload } from "./auth-v2-model";

const initialPasswordState: IPasswordState = {
    getPasswordResetResponse: '',
    resetPasswordResponse: '',
    resetPassValidations: [],
    getPasswordResetStatus: 'idle',
    resettingPassword: 'idle'
};

export const getPasswordResetEmail = createAsyncThunk<IBaseResponse, IGetResetPasswordPayload, { rejectValue: IBaseResponse }>(
    "getPasswordResetEmail",
    async ({ email }, { rejectWithValue }) => {
        return await httpAdapterInstance
            .get(`${EmployerApiEndpoints.FORGOT_PASSWORD}/?email=${email}`)
            .then((response: AxiosResponse<IBaseResponse>) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

export const resetPassword = createAsyncThunk<IBaseResponse, { token: string, payload: IResetPasswordPayload },
    { rejectValue: IBaseResponse & { errors: [] } }>(
        "resetPassword",
        async ({ token, payload }, { rejectWithValue }) => {
            /* 
                Set Auth Token manually here because user is not logged in at this point.
                Token is retrieved from the password reset link sent to user's email.
            */
            return await httpAdapterInstance
                .put(`${EmployerApiEndpoints.RESET_PASSWORD}?token=${token}`, payload, { headers: { Authorization: token } })
                .then((response: AxiosResponse<IBaseResponse>) => response?.data)
                .catch((error) => {
                    throw rejectWithValue(error.response.data);
                });
        }
    );

const passwordSlice = createSlice({
    name: "password",
    initialState: initialPasswordState,
    reducers: {
        resetGetPassReset: (state) => { state.getPasswordResetStatus = 'idle'; state.getPasswordResetResponse = '' },
        resetPassReset: (state) => { state.resettingPassword = 'idle'; state.resetPasswordResponse = '' }
    },
    extraReducers: (builder) => {
        // On Store PURGE reset the state
        builder.addCase(PURGE, () => {
            return initialPasswordState;
        });
        //get password reset mail
        builder.addCase(getPasswordResetEmail.pending, (state) => {
            state.getPasswordResetStatus = 'pending';
        });
        builder.addCase(getPasswordResetEmail.fulfilled, (state, action) => {
            state.getPasswordResetResponse = action?.payload?.message;
            state.getPasswordResetStatus = 'success';
        });
        builder.addCase(getPasswordResetEmail.rejected, (state, action) => {
            state.getPasswordResetStatus = 'failed';
            state.getPasswordResetResponse = action?.payload?.message ?? DefaultAPIErrorMsg;
        });
        //reset password
        builder.addCase(resetPassword.pending, (state) => {
            state.resettingPassword = 'pending';
        });
        builder.addCase(resetPassword.fulfilled, (state, action) => {
            state.resetPasswordResponse = action?.payload?.message;
            state.resettingPassword = 'success';
        });
        builder.addCase(resetPassword.rejected, (state, action) => {
            state.resettingPassword = 'failed';
            state.resetPasswordResponse = action?.payload?.message;
            state.resetPassValidations = action?.payload?.errors || [];
        });
    },
});

export const { resetGetPassReset, resetPassReset } = passwordSlice.actions;
export default passwordSlice;
