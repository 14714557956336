import ClearIcon from '@mui/icons-material/Clear';
import { DialogContent, DialogTitle, IconButton } from "@mui/material";
import { IInterviewPreviewDialog } from 'Modules/Core/Interviews/InterviewsModel';
import { useAppDispatch, useAppSelector } from 'helpers/hooks';
import parse from 'html-react-parser';
import moment from "moment";
import { useEffect, useState } from 'react';
import { ShAlert, ShDialog } from 'shared/SharedStyles/ShFeedback';
import { DefaultAPIErrorMsg } from 'shared/constants';
import { getJobHiringManagers } from 'store/slices/employer/create-job/job-title-slice';
import { getJobDetailsForInterview } from 'store/slices/employer/interviews/interviews-actions-slice';

export const InterviewPreviewDialog = ({ isDialogOpen, setIsDialogOpen, applicantInfo,
    interviewInfo, jobId, selectedTime }: IInterviewPreviewDialog) => {

    const dispatch = useAppDispatch();
    const { email, employerId } = useAppSelector((state) => state.auth.auth);
    const { employerDetails } = useAppSelector((state) => state.employer.employerDetails);
    const { jobHiringManagers, getJobHiringManagersStatus } = useAppSelector((state) => state.employer.jobTitle);
    const { jobDetails, getJobDetailsForInterviewStatus, getJobDetailsResponse } = useAppSelector((state) => state.employer.interviews.interviewsActions);
    const [interviewPreview, setInterviewPreview] = useState<string>('');
    const [hiringManagerName, setHiringManagerName] = useState<string>('');

    useEffect(() => {
        if (isDialogOpen) {
            dispatch(getJobDetailsForInterview({ jobId: jobId }));
        }
    }, [dispatch, isDialogOpen, jobId]);

    // get hiring manager details for preview.
    useEffect(() => {
        if (employerId) {
            dispatch(getJobHiringManagers({ empId: employerId }));
        }
    }, [dispatch, employerId]);

    useEffect(() => {
        if (getJobHiringManagersStatus === 'success') {
            const hm = jobHiringManagers?.find(hm => hm.id === jobDetails?.hiring_manager_id);
            if (hm) {
                setHiringManagerName(`${hm.first_name} ${hm.last_name}`);
            }
        }
    }, [getJobHiringManagersStatus, jobDetails?.hiring_manager_id, jobHiringManagers]);

    // Generate interview preview.
    useEffect(() => {
        if (getJobDetailsForInterviewStatus === 'success') {
            let preview = '';
            preview += `<p><strong>To: </strong>${applicantInfo.email}, ${email}</p>`;
            if (hiringManagerName) {
                preview += `<p><strong>CC: </strong>${hiringManagerName}</p>`;
            }
            preview += `<p><strong>Reply to: </strong>${email}</p>`;
            preview += `</br>`;
            preview += `<p><strong>Subject: </strong>You are shortlisted for ${interviewInfo.type_of_interview} interview with
             ${employerDetails?.company_name} for the position of ${jobDetails?.name}</p>`;
            preview += `</br>`;
            preview += `<p>Hello <strong>${applicantInfo.firstName} ${applicantInfo.lastName}, </strong></p>`;
            preview += `<p>Congratulations!</p>`;
            preview += `<p>You are shortlisted for ${interviewInfo.type_of_interview} interview with
             ${employerDetails?.company_name} for the position of ${jobDetails?.name}</p>`;
            preview += `</br>`;
            preview += `<p><strong>Interview details: </strong></p>`;
            preview += `<p>&nbsp;<strong>Company Name: </strong>${jobDetails?.employer_company_name}</p>`;
            preview += `<p>&nbsp;<strong>Title: </strong>${jobDetails?.name}</p>`;
            if (interviewInfo.schedule_date && selectedTime) {
                preview += `<p>&nbsp;<strong>Date and time of the interview: </strong>${moment(interviewInfo.schedule_date).format("MM/DD/YYYY")}, ${selectedTime}</p>`;
            } else if (interviewInfo.schedule_date) {
                preview += `<p>&nbsp;<strong>Date and time of the interview: </strong>${moment(interviewInfo.schedule_date).format("MM/DD/YYYY")}</p>`;
            } else {
                preview += `<p>&nbsp;<strong>Date and time of the interview: </strong></p>`;
            }
            preview += `<p>&nbsp;<strong>Mode of interview: </strong>${interviewInfo.type_of_interview}</p>`;
            preview += `<p>&nbsp;<strong>Interview Location/Address: </strong>${interviewInfo.info}</p>`;
            preview += `<p>&nbsp;<strong>Additional info: </strong>${interviewInfo.note}</p>`;
            preview += `</br>`;

            preview += `<p><strong>Job Details: </strong></p>`;
            preview += `<p style="margin: 0;">${jobDetails?.description}</p>`;
            setInterviewPreview(preview);
        }
    }, [applicantInfo.email, applicantInfo.firstName, applicantInfo.lastName, email, employerDetails?.company_name,
        getJobDetailsForInterviewStatus, hiringManagerName, interviewInfo.info, interviewInfo.note,
    interviewInfo.schedule_date, interviewInfo.type_of_interview, jobDetails?.description,
    jobDetails?.employer_company_name, jobDetails?.name, selectedTime]);

    return (<>
        <ShDialog open={isDialogOpen} aria-labelledby="title" onClick={e => e.stopPropagation()}>
            <DialogTitle id='title' textAlign='center' fontWeight={600}>Email Preview
                <IconButton className='close-btn' onClick={() => setIsDialogOpen(false)}><ClearIcon /></IconButton>
            </DialogTitle>
            <DialogContent dividers className='interview-preview'>
                {getJobDetailsForInterviewStatus === 'pending' &&
                    <ShAlert severity='info'>Preparing preview...</ShAlert>}
                {getJobDetailsForInterviewStatus === 'failed' && <ShAlert severity="error">
                    {getJobDetailsResponse ?? DefaultAPIErrorMsg}</ShAlert>}
                {/* Using 'html-react-parser' to parse the 'interviewPreview' cause it is stringified HTML. */}
                {parse(interviewPreview)}
            </DialogContent>
        </ShDialog>
    </>);
};