import { Box, FormControlLabel, Stack, Table } from '@mui/material';
import { lighten, styled } from '@mui/material/styles';
import { BorderColorDark, BorderColorLight, DarkModeBackground, ShGreen, WhiteColor } from 'shared/SharedStyles/styleConstants';

export const CreateJobOutletContainer = styled(Box)(
    ({ theme }) => ({
        [theme.breakpoints.up('xs')]: {
            minWidth: 'unset',
            '& .preview-paper-main': {
                padding: theme.spacing(1)
            }
        },
        [theme.breakpoints.up('sm')]: {
            minWidth: '500px',
            '& .security-tabs': {
                '& .MuiTabs-scroller': {
                    '& .MuiTabs-flexContainer': {
                        justifyContent: 'center'
                    }
                }
            },
            '& .preview-paper-main': {
                padding: theme.spacing(1.5)
            }
        },
        [theme.breakpoints.up('md')]: {
            minWidth: '500px',
            '& .preview-paper-main': {
                padding: theme.spacing(2)
            }
        },
        [theme.breakpoints.up('lg')]: {
            minWidth: '500px',
            '& .preview-paper-main': {
                padding: theme.spacing(2.5)
            }
        },
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        padding: 0,
        overflow: 'auto',
        height: '100%',
        maxWidth: '100%',
        backgroundColor: theme.palette.mode === 'light' ? WhiteColor : DarkModeBackground,
        '& .multiline-text': {
            minWidth: '100%',
            maxWidth: '100%',
            padding: theme.spacing(1),
            fontFamily: 'inherit',
            minHeight: '40px',
            borderColor: '#0000001f',
            borderRadius: '4px',
            backgroundColor: theme.palette.background.default,
            '&:active, &:focus, &:focus-within': {
                borderColor: theme.palette.primary.main,
                outlineColor: theme.palette.primary.main,
            }
        },
        '& tr': {
            '& td input': {
                '&:focus-visible, &:focus, &:active': {
                    backgroundColor: theme.palette.mode === 'light' ? 'whitesmoke' : BorderColorDark
                }
            },
            '&:last-child td': { border: 0 },
            '& .MuiInput-underline': {
                border: 'none',
                '&:before': {
                    border: 'none'
                },
                '&.Mui-error': {
                    backgroundColor: lighten(theme.palette.error.light, 0.8),
                    color: theme.palette.getContrastText(lighten(theme.palette.error.light, 0.8))
                }
            },
            '& td.action-cell': {
                width: '50px',
                paddingLeft: 0
            },
            '& td.selection-cell': {
                width: '130px',
                paddingRight: 0
            }
        },
        '& .preview-paper-main': {
            overflow: 'auto',
            width: '100%',
            '& .job-desc-txt-area': {
                padding: theme.spacing(1),
                minWidth: '50vw',
                minHeight: '400px',
                maxWidth: '80vw',
                '&:active, &:focus, &:focus-within': {
                    borderColor: theme.palette.primary.main,
                    outlineColor: theme.palette.primary.main,
                }
            }
        },
        '& .elements-stack': {
            maxWidth: '1000px'
        },
        '& .MuiStepConnector-root': {
            minWidth: theme.spacing(3)
        },
        '& .create-job-tabs': {
            alignItems: 'center',
            borderBottom: `1px solid ${theme.palette.mode === 'light' ? BorderColorLight : BorderColorDark}`,
            maxWidth: '100%',
            width: '100%',
            minHeight: '60px'
        }
    })
);

export const OrderTableSummaryStyled = styled(Table)(({ theme }) => ({
    "& .no-border": {
        border: 0,
    },
    "& .rowStyle": { justifyContent: "start" },
    "& .no-left-border": {
        borderLeft: 0,
    },
    "& .totalAmountCell": {
        border: `1px solid ${theme.palette.mode === 'light'
            ? BorderColorLight
            : BorderColorDark
            }`,
        backgroundColor: theme.palette.mode === 'light'
            ? BorderColorLight
            : BorderColorDark,
        fontWeight: "bold",
    },
    "& tr": {
        "& th": {
            "&:not(:last-of-type)": {
                borderRight: `1px solid ${theme.palette.mode === 'light'
                    ? BorderColorLight
                    : BorderColorDark
                    }`,
                borderColor: theme.palette.mode === 'light'
                    ? BorderColorLight : BorderColorDark,
            },
        },
        "& td": {
            borderLeft: `1px solid ${theme.palette.mode === 'light'
                ? BorderColorLight
                : BorderColorDark
                }`,
            borderBottom: `1px solid ${theme.palette.mode === 'light'
                ? BorderColorLight
                : BorderColorDark
                }`,
        },
    },
}));

export const BundleBox = styled(Box)(
    ({ theme }) => ({
        [theme.breakpoints.up('xs')]: {
            padding: `${theme.spacing(0)} ${theme.spacing(1)}`
        },
        [theme.breakpoints.up('sm')]: {
            padding: `${theme.spacing(0)} ${theme.spacing(2)}`
        },
        [theme.breakpoints.up('md')]: {
            padding: `${theme.spacing(0)} ${theme.spacing(2)}`
        },
        [theme.breakpoints.up('lg')]: {
            padding: `${theme.spacing(0)} ${theme.spacing(2)}`
        },
        width: '100%'
    })
);

export const BundleFormControlLabel = styled(FormControlLabel)(
    ({ theme }) => ({
        '& .MuiFormControlLabel-label': {
            flex: 1
        },
        border: `2px solid ${theme.palette.mode === 'light' ? BorderColorLight : BorderColorDark}`,
        borderRadius: '5px',
        padding: theme.spacing(1),
        paddingLeft: theme.spacing(0.5),
        paddingRight: theme.spacing(1.5),
        marginLeft: 0,
        marginRight: 0,
        marginBottom: theme.spacing(1),
        '&.selected-package': {
            borderColor: ShGreen,
            '& .MuiFormControlLabel-root.Mui-checked': {
                color: ShGreen
            }
        }
    })
);

export const JobDetailsActionsStack = styled(Stack)(({ theme }) => ({
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    position: 'sticky',
    bottom: 0,
    left: 0,
    zIndex: 1,
    background: theme.palette.background.paper,
    borderTop: `1px solid ${theme.palette.mode === 'light' ? BorderColorLight : BorderColorDark}`,
}));
