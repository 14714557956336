import PlaceIcon from "@mui/icons-material/Place";
import ScheduleIcon from '@mui/icons-material/Schedule';
import { Alert, Box, CardHeader, CircularProgress, Container, Stack, Typography } from "@mui/material";
import CtWellandLogo from 'assets/logos/ct-welland-logo.svg';
import { useAppDispatch, useAppSelector } from "helpers/hooks";
import moment from "moment";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ShGreenBtn } from "shared/SharedStyles/ShInputs";
import { ShPaper } from "shared/SharedStyles/ShSurfaces";
import { getEmployerPostedJobs } from "store/slices/employer/employer-sub-domain/employer-sub-domain-slice";

export const CareerPagesHome = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { employerPostedJobs, employerByMask, getEmployerPostedJobsStatus, getEmployerPostedJobsResponse } = useAppSelector(state => state.employer.employerSubDomain);

    // Get jobs list on page load.
    useEffect(() => {
        if (employerByMask?.id) {
            dispatch(getEmployerPostedJobs({ employerId: employerByMask?.id }))
        }
    }, [dispatch, employerByMask?.id]);

    return (
        <Stack height='100%'>
            <Box overflow='auto' flex={1}>
                <Container maxWidth='md'>
                    <Box marginY={1}>
                        <ShPaper height='unset' variant='outlined'>
                            <Stack direction='row' alignItems='center' columnGap={1}>
                                <img height={100} width={100} src={CtWellandLogo} alt="Logo" />
                                <Stack >
                                    <Typography variant="h6">Welcome to CTwelland jobs.</Typography>
                                    <Typography variant="subtitle2" textAlign='right'>Powered by Smoothhiring</Typography>
                                </Stack>
                            </Stack>
                        </ShPaper>
                        <Stack marginTop={1} rowGap={2}>
                            {getEmployerPostedJobsStatus === 'failed' && <Alert severity='error'>{getEmployerPostedJobsResponse}</Alert>}

                            {getEmployerPostedJobsStatus === 'pending' ?
                                <Box marginTop={2} width='100%' display='flex' justifyContent='center'><CircularProgress size={100} color='success' /></Box> :
                                employerPostedJobs?.map(job =>
                                    <ShPaper variant='outlined' key={job.id}>
                                        <CardHeader
                                            action={<ShGreenBtn borderRadius={20} onClick={() => {
                                                navigate(`/careers/${job.id}`);
                                            }}> View
                                                Job</ShGreenBtn>}
                                            title={
                                                <Typography variant="subtitle1" paddingBottom={1}>
                                                    {job.name}
                                                </Typography>
                                            }
                                            subheader={
                                                <>
                                                    <Stack direction='row' alignItems='center' columnGap={1}>
                                                        <ScheduleIcon fontSize='small' />
                                                        <Typography variant="body2" color="text.secondary" alignItems='center' justifyContent='center'>
                                                            {moment(job.starts_at).fromNow()}
                                                        </Typography>
                                                    </Stack>
                                                    <Stack direction='row' alignItems='center' columnGap={1} marginTop={1}>
                                                        <PlaceIcon fontSize='small' />
                                                        <Typography variant="body2" color="text.secondary">
                                                            {`${job.city}, ${job.state}`}
                                                        </Typography>
                                                    </Stack>
                                                </>
                                            }
                                        />
                                    </ShPaper>
                                )}
                        </Stack>
                    </Box>
                </Container>
            </Box>
            {/* <bOX MARGIN='AUTO'>
                {JOBSlIST?.LENGTH > 0 && PAGINATIONpARAMS && PAGINATIONpARAMS.TOTALpAGES > 0 && (
                    <jOBSfOOTER PAGINATIONpARAMS={PAGINATIONpARAMS} ISsMsCREEN={ISsMsCREEN} CAREERpAGE={TRUE}
                                GETjOBS={(PAGEnO, PAGEsIZE) => GETjOBS(PAGEnO, PAGEsIZE, SORTpARAMS, SEARCHkEY)}/>
                )}
            </bOX> */}
        </Stack>
    );
}