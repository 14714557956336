import NearMeIcon from '@mui/icons-material/NearMe';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import VerifiedIcon from '@mui/icons-material/Verified';
import { Chip, Grow, Stack, Typography } from '@mui/material';
import { ResourceTypography, TemplateHomesStack } from 'Modules/Marketing/Resources/Resources.styled';
import { Packer } from 'docx';
import { saveAs } from 'file-saver';
import { IsSmScreen } from 'helpers/hooks';
import React, { useState } from 'react';
import { ShButton, ShWordpressButton } from 'shared/SharedStyles/ShInputs';
import { ShContainer } from 'shared/SharedStyles/ShLayouts';
import { ShPaper } from 'shared/SharedStyles/ShSurfaces';
import { PrimaryWordpressThemeColor } from 'shared/SharedStyles/styleConstants';
import { jobOfferTemplateData } from '../OfferTemplateConstants';
import { OfferTemplatePreviewFields } from '../OfferTemplatePreviewFields';
import { ResourceOtherSimilarResourcesSideContainer } from 'Modules/Marketing/Resources/ResourceOtherSimilarResourcesSideContainer';
import { GenerateInformalJobOfferDocument, generateInformalJobOfferPreviewContent } from '../OfferLetterWordDocConstants';
import { SHSignUpLink } from 'shared/constants';

const InformalJobOfferTemplatePage = () => {
    const [salary, setSalary] = useState(jobOfferTemplateData.salary);
    const [startDate, setStartDate] = useState(jobOfferTemplateData.startDate);
    const [companyName, setCompanyName] = useState(jobOfferTemplateData.companyName);
    const [candidateName, setCandidateName] = useState(jobOfferTemplateData.candidateName);
    const [jobTitle, setJobTitle] = useState(jobOfferTemplateData.jobTitle);
    const [employmentType, setEmploymentType] = useState(jobOfferTemplateData.employmentType);
    const [employmentHours, setEmploymentHours] = useState(jobOfferTemplateData.employmentHours);
    const [department, setDepartment] = useState(jobOfferTemplateData.department);
    const [bonusPrograms, setBonusPrograms] = useState(jobOfferTemplateData.bonusPrograms);
    const [vacationDays, setVacationDays] = useState(jobOfferTemplateData.vacationDays);
    const [contractDuration, setContractDuration] = useState(jobOfferTemplateData.contractDuration);
    const [employmentAgreement, setEmploymentAgreement] = useState(jobOfferTemplateData.employmentAgreement);
    const [responseDate, setResponseDate] = useState(jobOfferTemplateData.responseDate);
    const [managerName, setManagerName] = useState(jobOfferTemplateData.managerName);
    const [contactDetails, setContactDetails] = useState(jobOfferTemplateData.contactDetails);
    const [yourName, setYourName] = useState(jobOfferTemplateData.yourName);
    const [signature, setSignature] = useState(jobOfferTemplateData.signature);
    const [benefits, setBenefits] = useState<string[]>(jobOfferTemplateData.benefits);
    const isSmScreen = IsSmScreen();

    const handleAddBenefit = (newBenefit: string) => {
        if (newBenefit.trim() !== '') {
            setBenefits([...benefits, newBenefit.trim()]);
        }
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            const newBenefit = (e.target as HTMLInputElement).value.trim();
            handleAddBenefit(newBenefit);
            (e.target as HTMLInputElement).value = '';
        }
    };

    const handleDeleteBenefit = (index: number) => {
        const updatedBenefits = [...benefits];
        updatedBenefits.splice(index, 1);
        setBenefits(updatedBenefits);
    };

    const handleDownload = async () => {
        try {

            const doc = GenerateInformalJobOfferDocument({
                companyName: jobOfferTemplateData.companyName,
                candidateName:  jobOfferTemplateData.candidateName,
                jobTitle: jobOfferTemplateData.jobTitle,
                employmentType: jobOfferTemplateData.employmentType,
                employmentHours: jobOfferTemplateData.employmentHours,
                department: jobOfferTemplateData.department,
                salary: jobOfferTemplateData.salary,
                vacationDays: jobOfferTemplateData.vacationDays,
                bonusPrograms: jobOfferTemplateData.bonusPrograms,
                startDate: jobOfferTemplateData.startDate,
                contractDuration: jobOfferTemplateData.contractDuration,
                employmentAgreement:jobOfferTemplateData.employmentAgreement,
                responseDate: jobOfferTemplateData.responseDate,
                managerName: jobOfferTemplateData.managerName,
                contactDetails: jobOfferTemplateData.contactDetails,
                yourName: jobOfferTemplateData.yourName,
                signature: jobOfferTemplateData.signature,
                benefits: jobOfferTemplateData.benefits,
            });

            const blob = await Packer.toBlob(doc);
            saveAs(blob, 'informal_job_offer_template.docx');

        } catch (error) {
            console.error('Error generating or downloading document:', error);
        }
    };

    return (
        <>
            <ShContainer maxWidth="xl" height="100%" margin="auto">
                <Stack paddingTop={4} paddingBottom={2}>
                    <ShPaper elevation={6} >
                        <Stack>
                            <Grow in={true} timeout={1000} mountOnEnter unmountOnExit>
                                <Stack justifyContent='center' padding={isSmScreen ? 0 : 2}>
                                    <ResourceTypography textAlign="center" gutterBottom variant="body2" fontWeight={700} color={PrimaryWordpressThemeColor}>
                                        HR Resources | Crafted by Industry Experts
                                    </ResourceTypography>
                                    {isSmScreen ?
                                        <ResourceTypography textAlign="center" gutterBottom fontSize={15} fontWeight={700}>
                                            Informal Job Offer Template
                                        </ResourceTypography>
                                        :
                                        <ResourceTypography textAlign="center" gutterBottom fontSize={25} fontWeight={700}>
                                            Informal Job Offer Template
                                        </ResourceTypography>}
                                    {isSmScreen ?
                                        <ResourceTypography textAlign="center" fontSize={13}>
                                            These templates simplify the creation of compelling job offers,
                                            ensuring your hiring process is streamlined and efficient.
                                            Customize the details such as salary, start date, benefits, and more.
                                            Download the finalized offer as a Word document for easy distribution.
                                        </ResourceTypography>
                                        :
                                        <ResourceTypography textAlign="center" fontSize={15}>
                                            These templates simplify the creation of compelling job offers,
                                            ensuring your hiring process is streamlined and efficient.
                                            Customize the details such as salary, start date, benefits, and more.
                                            Download the finalized offer as a Word document for easy distribution.
                                        </ResourceTypography>}
                                    <Stack paddingTop={3} justifyContent='center' paddingBottom={1} direction={isSmScreen ? 'column' : 'row'} spacing={1}>
                                        <Chip icon={<VerifiedIcon />} label="Customizable Offers" color="primary" variant='outlined' />
                                        <Chip icon={<VerifiedIcon />} label="Easy Download" color="primary" variant='outlined' />
                                        <Chip icon={<VerifiedIcon />} label="Professional Format" color="primary" variant='outlined' />
                                        <Chip icon={<PlaylistAddIcon />} label="Efficient Hiring" color="primary" />
                                    </Stack>
                                </Stack>
                            </Grow>
                        </Stack>
                    </ShPaper>
                </Stack>
                <OfferTemplatePreviewFields
                    isSmScreen={isSmScreen}
                    salary={salary}
                    startDate={startDate}
                    companyName={companyName}
                    candidateName={candidateName}
                    jobTitle={jobTitle}
                    employmentHours={employmentHours}
                    department={department}
                    bonusPrograms={bonusPrograms}
                    responseDate={responseDate}
                    employmentType={employmentType}
                    vacationDays={vacationDays}
                    contractDuration={contractDuration}
                    employmentAgreement={employmentAgreement}
                    managerName={managerName}
                    contactDetails={contactDetails}
                    yourName={yourName}
                    signature={signature}
                    benefits={benefits}
                    setSalary={setSalary}
                    setStartDate={setStartDate}
                    setCompanyName={setCompanyName}
                    setCandidateName={setCandidateName}
                    setJobTitle={setJobTitle}
                    setEmploymentHours={setEmploymentHours}
                    setDepartment={setDepartment}
                    setBonusPrograms={setBonusPrograms}
                    setResponseDate={setResponseDate}
                    setEmploymentType={setEmploymentType}
                    setVacationDays={setVacationDays}
                    setContractDuration={setContractDuration}
                    setEmploymentAgreement={setEmploymentAgreement}
                    setManagerName={setManagerName}
                    setContactDetails={setContactDetails}
                    setYourName={setYourName}
                    setSignature={setSignature}
                    handleKeyDown={handleKeyDown}
                    handleDeleteBenefit={handleDeleteBenefit} />

                <Grow in={true} timeout={1000} mountOnEnter unmountOnExit>
                    <Stack padding={isSmScreen ? 0 : 3} paddingBottom={10} spacing={2} maxWidth={'950px'} margin={'auto'}>
                        <ShPaper elevation={5} >
                            <Stack padding={2} direction={'row'} spacing={3} justifyContent={'space-between'}>
                                <ShButton onClick={handleDownload} variant="contained" color="primary" size='small'> Download (.docx) </ShButton>
                                <Chip color='success' style={{ color: 'white', maxWidth: '110px' }} label="Live Preview" />
                            </Stack>
                            <Stack padding={2} direction={'column'} spacing={3}>
                            {generateInformalJobOfferPreviewContent({
                                    companyName: companyName,
                                    candidateName:  candidateName,
                                    jobTitle: jobTitle,
                                    employmentType: employmentType,
                                    employmentHours: employmentHours,
                                    department: department,
                                    salary: salary,
                                    vacationDays: vacationDays,
                                    bonusPrograms: bonusPrograms,
                                    startDate: startDate,
                                    contractDuration: contractDuration,
                                    employmentAgreement:employmentAgreement,
                                    responseDate: responseDate,
                                    managerName: managerName,
                                    contactDetails: contactDetails,
                                    yourName: yourName,
                                    signature: signature,
                                    benefits: benefits
                                })}
                            </Stack>

                        </ShPaper>
                    </Stack>
                </Grow>
                <Stack maxWidth={'md'} margin='auto' padding={3} paddingBottom={4}>
                    <ResourceOtherSimilarResourcesSideContainer/>
                </Stack>
                <TemplateHomesStack maxWidth={'md'} margin={'auto'} color="white" spacing={2} marginBottom={5} backgroundColor={PrimaryWordpressThemeColor}>
                    <ResourceTypography gutterBottom variant="h6" fontWeight="bold">
                        📋 No candidates yet? Post your job to 100+ boards! 🌟
                    </ResourceTypography>
                    <ResourceTypography gutterBottom variant="subtitle1">
                        LinkedIn, Talent.com, career page - place your job on multiple platforms for FREE with just one click.
                    </ResourceTypography>
                    <ShWordpressButton href={SHSignUpLink} size="large" color="primary"
                        variant="contained" endIcon={<NearMeIcon />}>
                        <Typography fontWeight="bold">Post a Free Job &nbsp;</Typography>
                    </ShWordpressButton>
                </TemplateHomesStack>
            </ShContainer>
        </>


    );
};

export default InformalJobOfferTemplatePage;
