import { Divider, Stack, Toolbar, Typography } from "@mui/material";
import { Notification } from "Modules/Core/Notification";
import { Outlet, Link as RouterLink, useLocation } from "react-router-dom";
import { ContactInfo } from "shared/SharedComponents/ContactInfo";
import { ShLogo } from "shared/SharedComponents/ShLogo";
import ThemeToggler from "shared/SharedComponents/ThemeToggler";
import { DesktopOnlyStack, MobileOnlyStack } from "shared/SharedStyles/ShLayouts";
import { ShMuiLink } from "shared/SharedStyles/ShNavigation";
import { ShAppBar } from "shared/SharedStyles/ShSurfaces";
import { AppLoginOutlet } from "./AppLogin.styled";

export const AppLoginWrapper = () => {

    const pathName = useLocation()?.pathname?.split('/').pop() as 'login' | 'sign-up';

    return (<>
        {/* Snackbar type Notifications component */}
        <Notification />
        <Stack height='100vh'>
            <ShAppBar position="sticky">
                <Toolbar>
                    <Stack width='100%' direction='row' justifyContent='space-between' alignItems='center'>
                        <ShLogo link="/accounts/login" />
                        <Stack direction='row' alignItems='center'>
                            {/* Hiding sign in button in top nav if the user is already in login page */}
                            {pathName !== 'login' && <>
                                <DesktopOnlyStack flexDirection='row' alignItems='center' mr={2}>
                                    <Typography variant='body2' whiteSpace='nowrap'>
                                        Already have an account?
                                    </Typography>
                                    <ShMuiLink ml={1} component={RouterLink} to='/accounts/login' variant='body2'
                                        underline='hover'>Sign In</ShMuiLink>
                                </DesktopOnlyStack>
                            </>}
                            <DesktopOnlyStack direction='row' columnGap={2}>
                                <ContactInfo showDivider dividerOrientation='vertical' />
                            </DesktopOnlyStack>
                            <ThemeToggler />
                        </Stack>
                    </Stack>
                </Toolbar>
            </ShAppBar>
            <AppLoginOutlet>
                <Outlet />
            </AppLoginOutlet>
            {/* Contact info */}
            <MobileOnlyStack padding={2} rowGap={1}>
                <Divider />
                <ContactInfo />
            </MobileOnlyStack>
        </Stack>
    </>)
}