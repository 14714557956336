import { useAppDispatch, useAppSelector } from "helpers/hooks";
import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { TExcludedSubdomains } from "shared/SharedModels";
import { ExcludedSubdomains } from "shared/constants";
import { getEmployerDetailsByMaskingName } from "store/slices/employer/employer-sub-domain/employer-sub-domain-slice";

export const AppWrapper = () => {

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { employerByMask, getEmployerByMaskingNameStatus } = useAppSelector(state => state.employer.employerSubDomain);

    // get subdomain from url, if resources navigate to resources related pages.
    const subdomain = new URL(window.location.href)?.hostname?.split('.')[0]?.toLowerCase() as TExcludedSubdomains;

    useEffect(() => {
        if (subdomain === 'resources' || subdomain === 'resources-staging') {
            navigate('/resources');
        } else if (subdomain === 'tools') {
            navigate('/tools');
        }
        else {
            if (!employerByMask && getEmployerByMaskingNameStatus === 'idle' && !ExcludedSubdomains.includes(subdomain)) {
                dispatch(getEmployerDetailsByMaskingName({ maskingName: subdomain }));
            }
        }
    }, [dispatch, employerByMask, getEmployerByMaskingNameStatus, navigate, subdomain]);

    useEffect(() => {
        if (employerByMask) {
            navigate('/careers')
        }
    }, [employerByMask, navigate]);

    // Navigate to 404 if employer details are not found.
    // useEffect(() => {
    //     if (getEmployerByMaskingNameStatus === 'failed' && isEmployerNotFound) {
    //         navigate('/404');
    //     }
    // }, [getEmployerByMaskingNameStatus, isEmployerNotFound, navigate]);

    // New useEffect to handle apply-job redirection
    useEffect(() => {
        let currentPath = window.location.pathname;
        if (currentPath.startsWith('/apply') || currentPath.startsWith('/apply-job')) {
            currentPath = currentPath.replace('/apply/', '/apply-job/');
            const newPath = `/candidate${currentPath}`;
            const searchParams = window.location.search; // Get query params
            navigate(newPath + searchParams);
        }
    }, [navigate]);

    return (<Outlet />);
}