import { Box, styled } from "@mui/material";
import { BorderColorDark, BorderColorLight, DarkModeBackground, WhiteColor } from "shared/SharedStyles/styleConstants";

export const ApplicantWrapperStyled = styled(Box)(
    ({ theme }) => ({
        flex: 1,
        display: 'flex',
        padding: 0,
        overflow: 'auto',
        height: '100%',
        flexDirection: 'column',
        maxWidth: '100%',
        backgroundColor: theme.palette.mode === 'light' ? WhiteColor : DarkModeBackground,
        '& .applicant-tabs': {
            alignItems: 'center',
            borderBottom: `1px solid ${theme.palette.mode === 'light' ? BorderColorLight : BorderColorDark}`,
            maxWidth: '100%',
            width: 'fit-content',
            minHeight: '60px'
        }
    })
);

export const ShImageWithIconBox = styled(Box)(({ theme, ...props }) => ({
    '& svg': {
        height: '100%',
        width: '100%'
    }
}));