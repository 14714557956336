
export type ApplicantDetailsSection = 'profile' | 'resume' | 'comments' | 'questions' | 'stage';

export enum ApplicantPathEnum {
    Profile = 'profile',
    Resume = 'resume',
    Comments = 'comments',
    Questions = 'questions',
    Stage = 'stage'
};

