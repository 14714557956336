import { Box, Stack, Tab } from "@mui/material";
import { useAppDispatch, useAppSelector } from "helpers/hooks";
import { useNotification } from "Modules/Core/Notification";
import { useEffect, useRef, useState } from "react";
import { ShButton } from "shared/SharedStyles/ShInputs";
import { ShTabs } from "shared/SharedStyles/ShNavigation";
import { ShPaper } from "shared/SharedStyles/ShSurfaces";
import { downloadApplicantHiringGuide, resetDownloadApplicantHiringGuide } from "store/slices/employer/applicants/applicant-actions-slice";
import { IApplicant } from "../ApplicantsModel";
import { ApplicantComments } from "./ApplicantComments";
import { ApplicantProfile } from "./ApplicantProfile";
import { ApplicantDetailsSection, ApplicantPathEnum } from "./ApplicantProfileModel";
import { ApplicantQuestions } from "./ApplicantQuestions";
import { ApplicantResume } from "./ApplicantResume";

export const ApplicantProfileWrapper = () => {

    const notification = useNotification();
    const dispatch = useAppDispatch();
    const [currentSection, setCurrentSection] = useState<ApplicantDetailsSection>('profile');
    const { applicantDetails } = useAppSelector((state) => state.employer.applicants.applicantProfile);
    const { downloadingGuideStatus, downloadingGuideResponse } = useAppSelector(state => state.employer.applicants.applicantActions);
    // All applicant info UI element references which will be added to the intersection observer.
    const sectionRefs = useRef<{ [key: string]: Element | null }>({});

    // Intersection Observer setup
    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '50px',
            threshold: 0.1,
        };

        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    const sectionId = entry.target.id as ApplicantDetailsSection;
                    setCurrentSection(sectionId);
                    window.history.replaceState(null, '', `#${sectionId}`);
                }
            });
        }, options);

        Object.values(sectionRefs.current).forEach(ref => {
            if (ref) {
                observer.observe(ref);
            }
        });
    }, []);

    const downloadHiringGuide = (applicant: IApplicant) => {
        dispatch(downloadApplicantHiringGuide({ applicantId: applicant.candidateEmployerJobId, applicantName: applicant.fullName }));
    }


    // Show snackbar notifications on download list as csv.
    useEffect(() => {
        if (downloadingGuideStatus === 'success' || downloadingGuideStatus === 'failed') {
            notification.displayNotification({
                open: true,
                type: downloadingGuideStatus === 'success' ? 'success' : 'error',
                message: downloadingGuideResponse ?? 'Download successful'
            });
            dispatch(resetDownloadApplicantHiringGuide());
        }
    }, [dispatch, downloadingGuideResponse, downloadingGuideStatus, notification]);

    return (<>
        <Stack direction='row' alignItems='center' justifyContent='space-between'
            rowGap={1} flexWrap='wrap' paddingX={{ xs: 2, sm: 3, md: 4, lg: 4 }} marginBottom={1}>
            <ShTabs className="applicant-tabs" aria-label="Applicant Tabs" variant="scrollable" scrollButtons='auto'
                allowScrollButtonsMobile value={currentSection} onChange={(e, nV) => setCurrentSection(nV as ApplicantDetailsSection)}>
                <Tab label="Profile" value={ApplicantPathEnum.Profile} href="#profile" />
                <Tab label="Resume" value={ApplicantPathEnum.Resume} href="#resume" />
                {/* Show Questions tab only if atsPurchased is false */}
                {applicantDetails && !applicantDetails?.atsPurchased &&
                    <Tab label="Questions" value={ApplicantPathEnum.Questions} href="#questions" />
                }
                 <Tab label="Comments" value={ApplicantPathEnum.Comments} href="#comments" />
                {/* <Tab label="Stage" component={Link} value={ApplicantPathEnum.Stage} to={`/employer/job/${jobId}/applicant/${applicantId}/stage`} /> */}
            </ShTabs>
            <ShButton onClick={(e) => { e.stopPropagation(); applicantDetails && downloadHiringGuide(applicantDetails) }} variant='contained'
                disabled={downloadingGuideStatus === 'pending'} disableElevation color='primary' size='small'>
                {downloadingGuideStatus === 'pending' ? 'Downloading...' : 'Download Hiring Guide'}
            </ShButton>
        </Stack>
        <Box padding={{ xs: 2, sm: 3, md: 4, lg: 4 }} paddingTop='8px !important' flex={1}
            overflow='auto' id='applicantSectionsContainer' position='relative'>
            <section className="applicant-section" id="profile" ref={(el) => sectionRefs.current['profile'] = el}>
                <ApplicantProfile />
            </section>

            <ShPaper
                variant="outlined"
                marginBottom="10px"
                minHeight='250px'
                id="resume"
                ref={(el) => sectionRefs.current['resume'] = el}
                    style={{
                        flex: 1, 
                        display: 'flex',
                        flexDirection: 'column',
                        minHeight: '250px',
                        overflow: 'auto',
                    }}
                >
                <ApplicantResume />
            </ShPaper>

            {applicantDetails && !applicantDetails.atsPurchased && (
                <section className="applicant-section" id="questions" ref={(el) => sectionRefs.current['questions'] = el}>
                    <ApplicantQuestions />
                </section>
            )}

            <ShPaper variant="outlined" marginBottom="10px" marginTop="10px" minHeight='250px' height="unset"
                id="comments" ref={(el) => sectionRefs.current['comments'] = el}>
                <ApplicantComments />
            </ShPaper>
        </Box>
    </>);
}