import { Collapse, LinearProgress, TableCell, TableHead, Typography } from '@mui/material';
import TableBody from "@mui/material/TableBody";
import { JobsHeaders } from 'Modules/Core/JobsTs/JobsConstants';
import { JobActions } from 'Modules/Core/JobsTs/JobsListTs/JobActions';
import { JobSummary } from 'Modules/Core/JobsTs/JobsListTs/JobSummary';
import { IJobRow, IJobsComponentBase, JobsSortCols } from 'Modules/Core/JobsTs/JobsModel';
import moment from "moment";
import { MouseEvent, useState } from 'react';
import { ISortBase } from 'shared/SharedModels';
import { CollapsibleTableRow, RowSelectCell, ShTable, ShTableContainer, StyledTableRow, ThemeColorDivider } from 'shared/SharedStyles/ShDataDisplay';
import { ShAlert } from 'shared/SharedStyles/ShFeedback';
import { ShCheckbox } from 'shared/SharedStyles/ShInputs';
import { ShGreen } from 'shared/SharedStyles/styleConstants';
import { ShTableSortLabel } from './Jobs.styled';

// Table Row component
const Row = ({ getJobSummary, job, jobsSummary, selectJob, selectedJobs, jobsType, indx }: IJobRow) => {
    const [moreOptionsAnchorEl, setMoreOptionsAnchorEl] = useState<{ [key: number]: HTMLElement | null }>({ 0: null });

    const openMoreMenu = (e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>, indx: number) => {
        e.stopPropagation();
        setMoreOptionsAnchorEl({ ...moreOptionsAnchorEl, [indx]: e.currentTarget });
    };

    return (<>
        <>
            <StyledTableRow onClick={e => getJobSummary(job.id)}
                className={moreOptionsAnchorEl[job.id] ? 'is-actions-open' : ''} cursor='pointer'>
                <RowSelectCell onClick={(e) => e.stopPropagation()}>
                    <ShCheckbox id={'chk_' + indx} checked={selectedJobs?.includes(job.id)}
                        size='small' onChange={() => selectJob(job.id)} />
                </RowSelectCell>
                <TableCell>
                    <Typography variant="body2" fontWeight='bold'>{job.name}</Typography>
                </TableCell>
                <TableCell>
                    <Typography variant="body2">{job.owner}</Typography>
                </TableCell>
                {jobsType !== 'template' &&
                    <>
                        <TableCell>
                            <Typography variant="body2">{job.hiring_manager}</Typography>
                        </TableCell>
                        <TableCell>
                            {job.city ? <Typography variant="body2">{job.city}</Typography> :
                                <Typography color={ShGreen} variant="body2"> Remote</Typography>}
                        </TableCell>
                        <TableCell>
                            {job.state ? <Typography variant="body2">{job.state}</Typography> :
                                <Typography color={ShGreen} variant="body2"> Remote</Typography>}
                        </TableCell>
                    </>}

                {jobsType !== 'draft' && jobsType !== 'template' &&
                    <TableCell>
                        {job.starts_at && <Typography variant="body2">
                            {moment(job.starts_at).format("MMM DD, YYYY")}
                        </Typography>}
                    </TableCell>}
                {jobsType === 'expired' &&
                    <TableCell>
                        <Typography variant="body2">
                            {moment(job.ends).format("MMM DD, YYYY")}
                        </Typography>
                    </TableCell>}
                <TableCell>
                    <Typography variant="body2">{job.id}</Typography>
                </TableCell>
                <TableCell>
                    <JobActions key={job.id} anchorEl={moreOptionsAnchorEl[job.id]} job={job} jobsType={jobsType}
                        openMoreMenu={openMoreMenu} setMoreOptionsAnchorEl={setMoreOptionsAnchorEl} actionMenuType='popup' />
                </TableCell>
            </StyledTableRow>
            {jobsType !== 'draft' &&
                <CollapsibleTableRow>
                    <TableCell colSpan={jobsType === 'active' ? 8 : 9} padding='checkbox'>
                        {jobsSummary[job.id]?.getSummaryStatus === 'pending' && <LinearProgress />}
                        {jobsSummary[job.id]?.getSummaryStatus === 'failed' &&
                            <ShAlert severity="error">{jobsSummary[job.id]?.getSummaryResponse ?? 'Something went wrong!'}</ShAlert>}
                        {jobsSummary[job.id]?.getSummaryStatus === 'success' &&
                            <Collapse in={job.isExpanded} timeout="auto" unmountOnExit>
                                <JobSummary key={job.id} summary={jobsSummary[job.id]} jobsType={jobsType} job={job} />
                                <ThemeColorDivider />
                            </Collapse>
                        }
                    </TableCell>
                </CollapsibleTableRow>}
        </>
    </>);
}

export const JobsTableView = ({ getJobSummary, jobsList, jobsSummary, selectJob, selectedJobs,
    jobsType, sortParams, sortList }: IJobsComponentBase & ISortBase<JobsSortCols>) => {

    return (<>
        <ShTableContainer>
            <ShTable size="small">
                <TableHead>
                    <StyledTableRow cursor='pointer'>
                        <TableCell className='select-header'></TableCell>
                        {/* Sortable constant table headers */}
                        {JobsHeaders.sort((a, b) => a.order - b.order)
                            /*
                                Return undefined for column 'end' if jobsType is not 'expired'.
                                Return undefined for column 'starts_at', 'end' if jobsType is 'draft'.
                                This enables for conditional rendering of the header.
                            */
                            .filter(hdr => jobsType !== 'expired' && hdr.columName === 'ends' ? undefined :
                                jobsType === 'draft' && (hdr.columName === 'starts_at' || hdr.columName === 'ends') ? undefined : hdr)
                            .map((headCell) => (
                                headCell && (!headCell.hideColumnFor?.includes(jobsType)) &&
                                <TableCell key={headCell.columNameInDb} aria-label={headCell.label}
                                    sortDirection={sortParams?.orderBy === headCell.columNameInDb ? sortParams?.order : false}>
                                    {/* Show sort option only for enabled columns */}
                                    {headCell.isSortable ?
                                        <ShTableSortLabel
                                            active={sortParams?.orderBy === headCell.columNameInDb}
                                            direction={sortParams?.orderBy === headCell.columNameInDb ? sortParams?.order : 'asc'}
                                            onClick={() => sortList(headCell.columNameInDb ?? 'name', sortParams?.order === 'asc' ? 'desc' : 'asc')}
                                        >
                                            {headCell.label}
                                        </ShTableSortLabel> : <>{headCell.label}</>
                                    }
                                </TableCell>
                            ))}
                        <TableCell aria-label='Actions'></TableCell>
                    </StyledTableRow>
                </TableHead>
                <TableBody>
                    {jobsList.map((job, index) => (
                        // Row component holds both applicant and collapsible summary rows.
                        <Row key={index} indx={index} getJobSummary={getJobSummary} job={job}
                            jobsSummary={jobsSummary} jobsType={jobsType}
                            selectJob={selectJob} selectedJobs={selectedJobs} />
                    ))}
                </TableBody>
            </ShTable>
        </ShTableContainer>
    </>);
};