import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Grid, Rating, Skeleton, Stack, Typography } from '@mui/material';
import { renderFingerprintImage } from 'Modules/Core/Applicants/ApplicantHelpers';
import { SkillMap } from 'Modules/Core/Applicants/ApplicantsConstants';
import { FingerprintImageContainer, FingerprintImgContainer } from 'Modules/Core/Applicants/ApplicantsList/ApplicantsList.styled';
import { IApplicantSummaryProps } from 'Modules/Core/Applicants/ApplicantsModel';
import { useNotification } from 'Modules/Core/Notification';
import { useAppDispatch, useAppSelector } from 'helpers/hooks';
import moment from 'moment';
import { useEffect } from 'react';
import { ApiState } from 'shared/SharedModels';
import { ShAlert } from 'shared/SharedStyles/ShFeedback';
import { ShButton } from 'shared/SharedStyles/ShInputs';
import { DefaultAPIErrorMsg } from 'shared/constants';
import { resetDownloadApplicantHiringGuide } from 'store/slices/employer/applicants/applicant-actions-slice';
import { getFingerprintImage } from 'store/slices/employer/applicants/applicants-list-slice';

export const ApplicantSummary = ({ summary, applicantId, goToApplicantProfile, applicant,
    changeStage, isAtsPurchased, downloadHiringGuide }: IApplicantSummaryProps) => {

    const dispatch = useAppDispatch();
    const notification = useNotification();
    const { downloadingGuideStatus, downloadingGuideResponse } = useAppSelector(state => state.employer.applicants.applicantActions);

    // Show snackbar notifications on download list as csv.
    useEffect(() => {
        if (downloadingGuideStatus === 'success' || downloadingGuideStatus === 'failed') {
            notification.displayNotification({
                open: true,
                type: downloadingGuideStatus === 'success' ? 'success' : 'error',
                message: downloadingGuideResponse ?? 'Download successful'
            });
            dispatch(resetDownloadApplicantHiringGuide());
        }
    }, [dispatch, downloadingGuideResponse, downloadingGuideStatus, notification]);

    // Load fingerprint image once summary is loaded.
    useEffect(() => {
        dispatch(getFingerprintImage({ applicantId: applicantId }));
    }, [applicantId, dispatch]);

    // Append the svg into parent element once its loaded. 
    useEffect(() => {
        if (summary.getFingerprintImageStatus === 'success') {
            if (summary.baseballCardUrl) {
                renderFingerprintImage(summary.baseballCardUrl, applicantId + 'fingerprint_svg_parent');
            }
        }
    }, [applicantId, summary.baseballCardUrl, summary.getFingerprintImageStatus]);

    const showFailureAlerts = (apiState: ApiState, msg: string) => {
        switch (apiState) {
            case 'failed':
                return (<>
                    <Box marginBottom={2} width='100%'>
                        <ShAlert severity="error">{msg ?? DefaultAPIErrorMsg}</ShAlert>
                    </Box>
                </>);
            default:
                break;
        }
    };

    return (<>
        <Grid container columnSpacing={{ xs: 0, sm: 2, md: 2.5, lg: 3, xl: 3 }} padding={1}>
            {!isAtsPurchased && <FingerprintImgContainer item xs={12} sm={12} md={6} lg={5} xl={4}>
                {(summary?.getFingerprintImageStatus && summary?.getFingerprintImageResponse) &&
                    showFailureAlerts(summary?.getFingerprintImageStatus, summary?.getFingerprintImageResponse)}
                {summary?.getFingerprintImageStatus === 'pending' ?
                    <Skeleton width='100%' height='100%' animation='wave' /> :
                    <>
                        {/* /Placeholder/Parent block to which the fingerprint SVG will be appended. */}
                        <FingerprintImageContainer id={applicantId + 'fingerprint_svg_parent'}></FingerprintImageContainer>
                    </>}
            </FingerprintImgContainer>}
            <Grid item xs={12} sm={12} md={6} lg={7} xl={8}>
                <Stack height='100%' justifyContent='center' rowGap={1} paddingX={isAtsPurchased ? 2 : 0}>
                    <Stack>
                        <Typography variant='subtitle2' fontWeight='bold' gutterBottom>Education & Qualifications</Typography>
                        {summary?.educationQualificationRequirements?.map((eq, indx) => (
                            <Box key={indx} display="flex" alignItems="center">
                                {eq.hasRequirement ? (
                                    <CheckIcon fontSize="small" color='success' />
                                ) : (
                                    <CloseIcon fontSize="small" color='error' />
                                )}
                                <Typography variant='body2' ml={1}>
                                    {eq.name}
                                </Typography>
                            </Box>
                        ))}
                    </Stack>
                    <Stack>
                        <Typography variant='subtitle2' fontWeight='bold' gutterBottom>Skills</Typography>
                        {summary?.skillRequirements?.map((skl, indx) =>
                        (<Stack flexDirection='row' key={indx}>
                            <Rating id={applicantId + ''} size='small' max={3} value={SkillMap[skl.skillRating] ?? 0} readOnly />
                            <Typography variant='body2'>&nbsp;{skl.name}</Typography></Stack>))}
                    </Stack>
                    {summary?.commentSummaries?.map(comment => (
                        <Stack key={comment.commentId} justifyContent='center'>
                            <Typography variant='subtitle2' fontWeight='bold'
                                gutterBottom width='fit-content'>Comments</Typography>
                            <Stack flexDirection='row' alignItems='center' columnGap={1} marginBottom={0.5}>
                                <Typography variant='body2' whiteSpace='nowrap'>Commented by :</Typography>
                                <Typography variant='body2' fontWeight='bold'>{comment.commentOwner}</Typography>
                            </Stack>
                            <Stack flexDirection='row' alignItems='center' columnGap={1} marginBottom={0.5}>
                                <Typography variant='body2' whiteSpace='nowrap'>Comment :</Typography>
                                <Typography variant='body2' style={{ wordBreak: 'break-word' }}>{comment.comment}</Typography>
                            </Stack>
                            <Stack flexDirection='row' alignItems='center' columnGap={1} marginBottom={0.5}>
                                <Typography variant='body2' whiteSpace='nowrap'>Commented on:</Typography>
                                <Typography variant='body2'>{moment(comment.commentDate).format("MMM DD, YYYY")}</Typography>
                            </Stack>
                        </Stack>
                    ))}
                    <Stack paddingTop={1} direction='row' spacing={1} flexWrap='wrap' rowGap={1}>
                        <ShButton onClick={(e) => { e.stopPropagation(); goToApplicantProfile(applicant) }} variant='contained'
                            disableElevation color='primary' size='small'>View Profile</ShButton>
                        <ShButton onClick={(e) => { e.stopPropagation(); downloadHiringGuide(applicant) }} variant='contained'
                            disabled={downloadingGuideStatus === 'pending'} disableElevation color='primary' size='small'>
                            {downloadingGuideStatus === 'pending' ? 'Downloading...' : 'Download Hiring Guide'}
                        </ShButton>
                        {/* <StagesToggler includeOnlyInterviews={true} applicant={applicant} changeStage={changeStage} /> */}
                    </Stack>
                </Stack>

            </Grid>
        </Grid>
    </>);
};