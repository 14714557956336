import { styled } from '@mui/material/styles';
import { Box } from '@mui/system';
import CarouselImg1 from 'assets/Login/CarslBkgrnd1.svg';
import CarouselImg2 from 'assets/Login/CarslBkgrnd2.svg';
import CarouselImg3 from 'assets/Login/CarslBkgrnd3.svg';
import JobBoardsGrouped from 'assets/Login/JobBoardsGrouped.svg';
import Carousel from 'react-material-ui-carousel';
import { DarkModeBackground, PaperBorderRadius, ShGreenLightGradientToRight, WhiteColor } from 'shared/SharedStyles/styleConstants';

export const AppLoginOutlet = styled(Box)(
    ({ theme }) => ({
        background: theme.palette.mode === 'light' ? ShGreenLightGradientToRight : DarkModeBackground,
        flex: 1,
        overflow: 'auto'
    })
);

export const StyledCarousel = styled(Carousel)(
    ({ theme }) => ({
        flex: 1,
        height: '100%',
        maxHeight: '500px',
        minHeight: '500px',
        borderRadius: PaperBorderRadius,
        margin: 'auto',
        '& .carousel-items': {
            minHeight: '500px',
            height: '500px',
            padding: theme.spacing(4),
            border: theme.palette.mode === 'light' ? 'none' : `1px solid ${theme.palette.divider}`,
            borderRadius: PaperBorderRadius,
            '& .MuiButton-root': {
                color: WhiteColor
            }
        },
        '& .item1': {
            background: theme.palette.mode === 'light' ? 'linear-gradient(115.49deg, #417BEF 5.78%, #7BC3FA 91.85%)' : theme.palette.background.paper,
            '& .banner': {
                flex: 1,
                backgroundImage: `url(${CarouselImg1})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: '70% 100%',
                backgroundPosition: 'right bottom',
                '& button': {
                    textTransform: 'none',
                    fontSize: 'large',
                    width: 'fit-content'
                },
                '& .job-boards': {
                    marginTop: '10px',
                    height: '75%',
                    width: '40%',
                    backgroundImage: `url(${JobBoardsGrouped})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'contain',
                    backgroundPosition: 'left center'
                }
            }
        },
        '& .item2': {
            background: theme.palette.mode === 'light' ? 'linear-gradient(115.49deg, #19AEF7 5.78%, #CFECFA 97.08%)' : theme.palette.background.paper,
            '& .banner': {
                flex: 1,
                backgroundImage: `url(${CarouselImg2})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
                backgroundPosition: 'right bottom',
                '& button': {
                    width: 'fit-content',
                    textTransform: 'none',
                    fontSize: 'large'
                }
            }
        },
        '& .item3': {
            background: theme.palette.mode === 'light' ? 'linear-gradient(315deg, #E7F3FF 5.78%, #7BC3FA 97.08%)' : theme.palette.background.paper,
            '& .banner': {
                flex: 1,
                backgroundImage: `url(${CarouselImg3})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
                backgroundPosition: 'right bottom',
                '& button': {
                    textTransform: 'none',
                    width: 'fit-content',
                    fontSize: 'large'
                }
            }
        },
        '& .item-header': {
            textTransform: 'capitalize',
            color: WhiteColor
        },
        '& .carousel-btns': {
            zIndex: 2,
            bottom: PaperBorderRadius,
            height: 'fit-content',
            top: 'unset',
            '&:hover': {
                'button': {
                    backgroundColor: 'unset',
                }
            },
            'button': {
                top: 'calc(100% - 50px) !important',
                backgroundColor: 'unset',
                '&:hover': {
                    backgroundColor: 'unset',
                }
            }
        },
        '& .indicator-btns-cntnr': {
            marginTop: 0,
            position: 'relative',
            bottom: '40px',
            zIndex: 1,
            '& .indicator-btns': {
                color: WhiteColor,
                fontSize: 'small'
            }
        },
        '& .active-item-indicator': {
            position: 'relative',
            '&::before': {
                content: "''",
                position: 'absolute',
                top: '50%',
                left: '50%',
                height: '80%',
                width: '80%',
                borderRadius: '50%',
                transform: 'translate(-50%,-50%)',
                background: 'white'
            }
        }
    })
);
