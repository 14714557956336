import { IPricingFeaturesMap, IPricingTypeMap } from "Modules/Core/Pricing/PricingModel";

export const PricingTypes: IPricingTypeMap[] = [
    {
        pricingLabel: 'Basic',
        description: 'Candidate Fit',
        price: 'US$ 299',
        pricingType: 'BASIC',
        unit: 'Per Job'
    },
    {
        pricingLabel: 'Pro',
        description: 'Job Posting',
        price: 'US$ 399',
        pricingType: 'PRO',
        unit: 'Per Job'
    },
    {
        pricingLabel: 'Max',
        description: 'Expand Network',
        price: 'US$ 599',
        pricingType: 'MAX',
        unit: 'Per Job'
    }
];

export const PricingFeaturesMap: IPricingFeaturesMap[] = [
    {
        label: '1000’s of Job Matching Profiles',
        includesIn: ['BASIC', 'PRO', 'MAX']
    },
    {
        label: 'Instant Candidate ranking',
        includesIn: ['BASIC', 'PRO', 'MAX']
    },
    {
        label: 'Custom Interview Questions',
        includesIn: ['BASIC', 'PRO', 'MAX']
    },
    {
        label: 'Dedicated Account Manager',
        includesIn: ['BASIC', 'PRO', 'MAX']
    },
    {
        label: 'Schedule Interviews',
        includesIn: ['BASIC', 'PRO', 'MAX']
    },
    {
        label: 'Preferred Placement',
        includesIn: ['PRO', 'MAX']
    },
    {
        label: 'Access to Job Boards',
        includesIn: ['PRO', 'MAX']
    },
    {
        label: 'Linked In Premium board',
        includesIn: ['MAX']
    },
    // {
    //     label: 'Conduct Background Checks',
    //     includesIn: ['MAX']
    // }
];