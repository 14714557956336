export const rejectionTemplates = [
    { 
        title: "Formal Rejection Letter Template",
        description: "Customized for fomally rejecting applicants",
        path: "/formal-rejection-letter-template"
    },
    { 
        title: "Informal Rejection Letter Template",
        description: "Customized for informally rejecting applicants",
        path: "/informal-rejection-letter-template"
    },
    { 
        title: "Auto Rejection Letter Template",
        description: "Customized for auto rejecting applicants",
        path: "/auto-rejection-templates"
    }
    
];

export const jobRejectionTemplateData = {
    companyName: "Sample Company Inc.",
    recruiterName: "John Doe",
    jobTitle: "Software Engineer",
    department: "Engineering", // Department name
    contactDetails: "manager@samplecompany.com, +1-234-567-8901", // Contact details for queries
    candidateName: "Candidate Name", // Placeholder for candidate's name
    yourName: "Your Name", // Placeholder for your name
    signature: "Your Signature", // Placeholder for your signature
};

