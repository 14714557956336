import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IInterviewsSharedState } from 'Modules/Core/Interviews/InterviewsModel';
import { PURGE } from 'redux-persist';

const initialInterviewsSharedState: IInterviewsSharedState = { isInterviewDialogOpen: false };

const interviewsSharedSlice = createSlice({
    name: 'interviewsShared',
    initialState: initialInterviewsSharedState,
    reducers: {
        openInterviewDialog: (state, action: PayloadAction<IInterviewsSharedState>) => {
            state.isInterviewDialogOpen = true;
            state.applicantInfo = action.payload?.applicantInfo;
            state.candidateName = action.payload?.candidateName;
            state.interviewId = action.payload?.interviewId;
            state.jobId = action.payload?.jobId;
            state.candidateEmployerJobId = action.payload.candidateEmployerJobId;
            state.stage = action.payload.stage;
            state.usedIn = action.payload.usedIn;
        },
        closeInterviewDialog: (state) => {
            state.isInterviewDialogOpen = false;
            state.applicantInfo = undefined;
            state.candidateName = undefined;
            state.interviewId = undefined;
            state.jobId = undefined;
            state.candidateEmployerJobId = undefined;
            state.stage = undefined;
        },
    },
    extraReducers: (builder) => {
        // On Store PURGE reset the state
        builder.addCase(PURGE, () => {
            return initialInterviewsSharedState;
        });
    }
});

export const { closeInterviewDialog, openInterviewDialog } = interviewsSharedSlice.actions;
export default interviewsSharedSlice;
