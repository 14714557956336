import { Alert, Box, Divider, Grid, LinearProgress, Stack } from "@mui/material";
import { InterviewCard } from "Modules/Core/Interviews/InterviewCard";
import { InterviewsFooter } from "Modules/Core/Interviews/InterviewsFooter";
import { IInterviewsDashboard, IInterviewsListParams, InterviewSortCols, InterviewsType } from 'Modules/Core/Interviews/InterviewsModel';
import { InterviewsToolbar } from 'Modules/Core/Interviews/InterviewsToolbar';
import { useNotification } from "Modules/Core/Notification";
import { IsSmScreen, useAppDispatch, useAppSelector } from 'helpers/hooks';
import { useCallback, useEffect, useState } from 'react';
import { ISortParamsBase, SortOrder } from 'shared/SharedModels';
import { DefaultPageSize } from "shared/constants";
import { resetUpdateInterviewSchedule, resetUpdateInterviewStatus } from "store/slices/employer/interviews/interviews-actions-slice";
import { getInterviewsSummary, resetGetInterviewDetails } from "store/slices/employer/interviews/interviews-details-slice";
import { getInterviewsList } from 'store/slices/employer/interviews/interviews-list-slice';

export const Interviews = ({ gridProps, usedInDashboard }: IInterviewsDashboard) => {

    const isSmScreen = IsSmScreen();
    const dispatch = useAppDispatch();
    const notification = useNotification();
    const { interviewsSummary } = useAppSelector(state => state.employer.interviews.interviewsDetails);
    const { interviews, paginationParams, getInterviewsStatus,
        getInterviewsResponse } = useAppSelector(state => state.employer.interviews.interviewsList);
    const { updateInterviewStateStatus, updateInterviewStateResponse } = useAppSelector((state) => state.employer.interviews.interviewsActions);
    const [interviewsType, setInterviewsType] = useState<InterviewsType>('scheduled');
    const [sortParams, setSortParams] = useState<ISortParamsBase<InterviewSortCols>>({ orderBy: 'alphabetical', order: 'asc' });
    const { updateInterviewScheduleStatus } = useAppSelector((state) => state.employer.interviews.interviewsActions);

    const getInterviews = useCallback((interviewsType: InterviewsType, pageNo?: number, pageSize?: number,
        sortParams?: ISortParamsBase<InterviewSortCols>) => {
        const params: IInterviewsListParams = {
            status: 'Scheduled',
            pageNo: pageNo ?? 1,
            pageSize: pageSize ?? DefaultPageSize,
            sort: sortParams?.order ?? 'asc',
            sortBy: sortParams?.orderBy ?? 'alphabetical'
        };
        switch (interviewsType) {
            case 'scheduled':
                params.status = 'Scheduled';
                break;
            case 'completed':
                params.status = 'Completed';
                break;
            case 'cancelled':
                params.status = 'Cancelled';
                break;
        }
        dispatch(getInterviewsList(params))
    }, [dispatch]);

    useEffect(() => {
        if (interviews?.length === 0) {
            getInterviews(interviewsType);
        }
    }, [getInterviews, interviews?.length, interviewsType]);

    // Get interviews summary on page load.
    useEffect(() => {
        if (!interviewsSummary) {
            dispatch(getInterviewsSummary());
        }
    }, [dispatch, interviewsSummary]);

    // Load scheduled when in dashboard
    useEffect(() => {
        if (usedInDashboard) {
            getInterviews('scheduled');
        }
    }, [getInterviews, usedInDashboard]);

    useEffect(() => {
        // clear on unmount
        return () => {
            dispatch(resetGetInterviewDetails());
        }
    }, [dispatch]);

    // Refresh list on interview status updates.
    useEffect(() => {
        if (updateInterviewStateStatus === 'success') {
            dispatch(getInterviewsSummary());
            getInterviews(interviewsType);
            dispatch(resetUpdateInterviewStatus());
        } else if (updateInterviewStateStatus === 'failed') {
            notification.displayNotification({
                open: true,
                type: 'error',
                message: updateInterviewStateResponse
            });
            dispatch(resetUpdateInterviewStatus());
        }
    }, [dispatch, getInterviews, interviewsType, notification, updateInterviewStateResponse, updateInterviewStateStatus]);

    const sortList = (sortBy: InterviewSortCols, order: SortOrder) => {
        setSortParams({ order: order, orderBy: sortBy });
        getInterviews(interviewsType, paginationParams.requestedPageNumber,
            paginationParams.requestedPageSize, { order: order, orderBy: sortBy });
    };

    // Refresh list if any interview is updated.
    useEffect(() => {
        if (updateInterviewScheduleStatus === 'success') {
            getInterviews(interviewsType);
            // Reset state once interview is updated.
            dispatch(resetUpdateInterviewSchedule());

        }
    }, [dispatch, getInterviews, interviewsType, updateInterviewScheduleStatus]);

    return (<>
        <Stack padding={usedInDashboard ? 0 : 1} height='100%' minHeight={200}>
            {!usedInDashboard && interviewsSummary && <>
                <InterviewsToolbar interviewsType={interviewsType} setInterviewsType={setInterviewsType}
                    interviewsCount={interviewsSummary} sortList={sortList} sortParams={sortParams}
                    getInterviews={getInterviews} />
            </>}
            <Box flex={1} overflow='auto' paddingRight={1} paddingY={1}>
                {getInterviewsStatus === 'pending' && <LinearProgress />}
                {getInterviewsStatus === 'failed' && <Alert title={getInterviewsResponse} severity="error" />}
                {getInterviewsStatus === 'success' && interviews?.length === 0 &&
                    <Alert severity="info">No interviews found.</Alert>}
                <Grid container spacing={2} justifyItems='self-start'>
                    {interviews.map((interview) => (
                        <Grid item {...gridProps || { xs: 12, sm: 12, md: 6, lg: 4, xl: 3 }} key={interview.id} height='fit-content'>
                            <InterviewCard interview={interview} usedInDashboard={usedInDashboard} />
                        </Grid>
                    ))}
                </Grid>
            </Box>
            {!usedInDashboard && <>
                <Divider />
                <InterviewsFooter getInterviews={getInterviews} interviewsType={interviewsType} isSmScreen={isSmScreen}
                    paginationParams={paginationParams} />
            </>}
        </Stack>
    </>);
};