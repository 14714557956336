import dayjs from "dayjs";
import { IGeneratedTimeInterval } from "./InterviewsModel";

/*
* Creates to and from times based on an interval
*/
export const generateTimeIntervals = (stepMinutes: number): IGeneratedTimeInterval[] => {
    const intervals: IGeneratedTimeInterval[] = [];
    let start = dayjs().startOf('day');
    const end = dayjs().endOf('day');
    if (stepMinutes) {
        while (start <= end) {
            const startTime = start.format('h:mm A');
            const endTime = start.add(stepMinutes, 'minute').format('h:mm A');

            const interval: IGeneratedTimeInterval = {
                startTime: start.format('HH:mm'),
                endTime: start.add(stepMinutes, 'minute').format('HH:mm'),
                timeInterval: `${startTime} - ${endTime}`
            }
            intervals.push(interval);
            start = start.add(stepMinutes, 'minute');
        }
    }
    return intervals;
};

// Get the  interval to the current time
// export const getNoonInterval = (intervals: string[]) => {
//     const noon = dayjs().startOf('day').add(12, 'hour').format('h:mm A');
//     const closestInterval = intervals.find(interval => interval.startsWith(noon)) || intervals[0];
//     return closestInterval;
// };

export const getScheduledInterval = (intervals: string[]) => {
    const scheduledTime = dayjs().hour(12).minute(15).format('h:mm A');
    const closestInterval = intervals.find(interval => interval.startsWith(scheduledTime)) || intervals[0];
    return closestInterval;
}